import React from 'react'
import { useEffect, useRef } from 'react'
import { gantChart } from "./anychartGantt";
import { tokens } from "../../../assets/theme";
import { useTheme } from '@mui/material';
import { useLocalStorage } from "../../../hooks/useLocalStorage";

function Gantt(props) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    const [ganttDarkMode, setGanttDarkMode] = useLocalStorage("ganttDarkMode", "true");
    const chartContainerRef = useRef(null);
    
    useEffect(() => {
      chartContainerRef.current.style.touchAction = 'pan-x pan-y';
    }, []);

    useEffect(() => {
        //Making a chart instance
        const chart = gantChart("chart_container", props.data, props.handleClick, colors, props.startDate, props.endDate, companyKey, ganttDarkMode, props.company, props.openActivities);
    
        return () => {
          //Preventing duplicate renders
          chart.dispose();
        }
      }, [props.startDate, props.endDate, props.data]);
      

  return (
    <div id="chart_container" ref={chartContainerRef}></div>
  )
}

export default Gantt