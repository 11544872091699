import React, { useEffect, useRef } from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { fetchMachineStop, putMachineStopSplit, fetchMachineData } from "../../fetchers/machine";
import { useQuery } from "@tanstack/react-query";
import { tokens } from "../../assets/theme";
import { getStopReasonCodes } from "../stopReasonCodes/hooks/fetchers";
import AxionControls from "../../components/controls/AxionControls";
import Button from "../../components/controls/Button";
import SplitMachineStopRow from "./components/splitMachineStopRow";
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import { pushErrorMessage } from "../../utils/notifications";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import { pushNotification } from "../../utils/notifications";
import { format } from "date-fns";
import { useMsal, useAccount } from "@azure/msal-react";
import moment from 'moment-timezone';

export default function SplitMachineStop() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);

    const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
    const toDateRef = useRef();
    const toTimeRef = useRef();
    const fromDateRef = useRef();
    const fromTimeRef = useRef();

    const [machineStopId, setMachineStopId] = React.useState(0);
    const [originalMachineStop, setOriginalMachineStop] = React.useState();
    const [currentStop, setCurrentStop] = React.useState(
        {
            machineName: "",
            fromDate: new Date(),
            toDate: new Date(),
            stopReasonCodeId: 0,
            comment: "",
            //toDateTimeZone: new Date(),
            //fromDateTimeZone: new Date()
        }
    );
    const [createdStops, setCreatedStops] = React.useState([]);
    const [isValid, setIsValid] = React.useState(false);
    const [isFinished, setIsFinished] = React.useState(false);
    const [IsCurrentEndTimeStopEndTime, setIsCurrentEndTimeStopEndTime] = React.useState(false);
    const [IsCurrentStopOriginalStop, setIsCurrentStopOriginalStop] = React.useState(true);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [filteredStopReasonCodes, setFilteredStopReasonCodes] = React.useState([]);
    const navigate = useNavigate();

    const params = useParams();
    useEffect(() => {
        setMachineStopId(params.id);
    }, [params]);

    const machineStopQuery = useQuery(
        ["machineStop", machineStopId],
        () => fetchMachineStop(machineStopId),
        {
            // staleTime: Infinity
        }
    );

    const handleChange = (event) => {
        const { id, name, value, type, checked } = event.target

        if (id === "toDateOnly") {
            let dateTimeSV = getDateTimeSV(event.target.value, toTimeRef.current.value)
            if (!isNaN(new Date(dateTimeSV))) {
                setCurrentStop({ ...currentStop, toDate: dateTimeSV });
            }
        }
        else if (id === "toTimeOnly") {
            let dateTimeSV = getDateTimeSV(currentStop.toDate, event.target.value)
            if (!isNaN(new Date(dateTimeSV))) {
                setCurrentStop({ ...currentStop, toDate: dateTimeSV });
            }
        }
        else {
            setCurrentStop({
                ...currentStop,
                [id ? id : name]: type === "checkbox" ? checked : value
            })
        }

        setIsValid(checkIsValid());
        setIsCurrentEndTimeStopEndTime(checkIsCurrentEndTimeStopEndTime());
        setIsCurrentStopOriginalStop(checkIsCurrentStopOriginalStop());
    }

    const stopReasonCodesQuery = useQuery(["stopReasonCodes", companyKey], () => getStopReasonCodes(companyKey),
        {
            staleTime: Infinity
        }
    );

    useEffect(() => {
        setCurrentStop(machineStopQuery.data);
        setOriginalMachineStop(machineStopQuery.data);
        if (machineStopQuery.isFetched && machineStopQuery.data && machineStopQuery.data.machineId) {
            fetchMachineData(machineStopQuery.data.machineId).then((machine) => {
                setFilteredStopReasonCodes(stopReasonCodesQuery.data.filter(x => machine.machineStopReasonCodes.some(y => x.id === y.stopReasonCodeId)));
            }
            ).catch((error) => {
                console.log(error);
                pushErrorMessage(`Could not get info about the machine connected to the stop.`);
            })
        }
    }, [machineStopQuery.isFetched, machineStopQuery.data, stopReasonCodesQuery.data]);

    function getDateTimeFromTime(time) {
        if (isNaN(new Date(time))) {
            let datePart = companyKey === 2 ? "01/01/2000 " : "2000-01-01T";
            const inputTime = new Date(datePart + time);
            return inputTime;
        } else {
            return new Date(time);
        }
    }
    function getDateTimeSV(date, time) {

        time = time.toString().replace("T", " ");

        let dateSV = formatDate(date);
        let timeSV = formatFullTime(getDateTimeFromTime(time));

        let result = dateSV + "T" + timeSV;
        return result;
    }

    function formatDate(date) {
        return date ? new Date(date).toLocaleDateString('sv-SE') : "";
    }

    function formatTime(date) {
        return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' }) : "";
    }

    function formatFullTime(date) {
        return date ? new Date(date).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "";
    }

    function formatDateLocalized(date) {
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        return date ? new Date(date).toLocaleDateString(locale) : "";
    }

    function formatTimeLocalized(date) {
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        return date ? new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' }) : "";
    }

    function formatFullTimeLocalized(date) {
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';
        return date ? new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : "";
    }

    function formatFullDateTimeLocalized(date) {
        //Change for company locale setting
        let locale = companyKey === 2 ? 'en-US' : 'sv-SE';

        let formattedDate = new Date(date).toLocaleDateString(locale);
        let formattedTime = new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        return date ? formattedDate + " " + formattedTime : "";
    }

    function secondsDiff(date1, date2){
        const dateA =  moment(date1);
        const dateB =  moment(date2);

        return dateA.diff(dateB, 'seconds', true);
    }

    function handleAdd() {
        let stopToAdd = currentStop;
        let newCurrentStop = { ...originalMachineStop };
        stopToAdd.stopId = currentStop.id;

        if (createdStops.length < 1) {
            currentStop.fromDate = `${formatDate(currentStop.fromDateTimeZone)}T${formatFullTime(currentStop.fromDateTimeZone)}`;
        }

        stopToAdd.fromDate = `${formatDate(currentStop.fromDate)}T${formatFullTime(currentStop.fromDate)}`;
        stopToAdd.toDate = `${formatDate(currentStop.toDate)}T${formatFullTime(currentStop.toDate)}`;

        //Set endTime to original endTime if only seconds are diffing
        let originalStopFormatted = `${formatTime(originalMachineStop.toDateTimeZone)}`;
        if ((formatTime(currentStop.toDate) === originalStopFormatted) && (formatDate(currentStop.toDate) === formatDate(originalMachineStop.toDateTimeZone))) {
            stopToAdd.toDate = `${formatDate(originalMachineStop.toDateTimeZone)}T${formatFullTime(originalMachineStop.toDateTimeZone)}`;
        }

        if (createdStops.length === 0) {
            stopToAdd.type = "Update";
        }
        else {
            stopToAdd.type = "New";
        }
        
        setCreatedStops([...createdStops, stopToAdd]);

        newCurrentStop.fromDate = new Date(stopToAdd.toDate);
        newCurrentStop.toDate = new Date(originalMachineStop.toDateTimeZone);
        newCurrentStop.comment = currentStop.comment;

        fromDateRef.current.value = formatDateLocalized(stopToAdd.toDate);
        fromTimeRef.current.value = formatTimeLocalized(stopToAdd.toDate);
        toDateRef.current.value = formatDateLocalized(originalMachineStop.toDateTimeZone);
        toTimeRef.current.value = formatTimeLocalized(originalMachineStop.toDateTimeZone);

        setCurrentStop(newCurrentStop);
        setIsValid(checkIsValid());
        setIsFinished(checkIsFinished(stopToAdd));
        setIsCurrentEndTimeStopEndTime(true);
    }

    function checkIfValidTime(time){
        return moment(time, ['LT', 'HH:mm', 'HH:mm:ss'], true).isValid();
    }

    function checkIfValidDate(Date){
        return moment(Date, ['M/D/YYYY','MM/D/YYYY', 'M/DD/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD'], true).isValid();
    }
    
    function checkIsValid() {
        if (!currentStop || !toDateRef.current || !toTimeRef.current) {
            return false;
        }
        if (!checkIfValidTime(toTimeRef.current.value)){
            return false;
        }

        if (!checkIfValidDate(toDateRef.current.value)){
            return false;
        }

        if (isNaN(new Date(getDateTimeSV(toDateRef.current.value, toTimeRef.current.value)))) {
            return false;
        }

        // if (isNaN(new Date(getDateTimeSV(toDateRef.current.value, currentStop.toDateTimeZone)))) {
        //     return false;
        // }     

        if (new Date(getDateTimeSV(toDateRef.current.value, toTimeRef.current.value)) < new Date(currentStop.fromDateTimeZone)) {
            return false;
        }

        if (new Date(getDateTimeSV(toDateRef.current.value, toTimeRef.current.value)) > new Date(originalMachineStop.toDateTimeZone)) {
            return false;
        }
        return true;
    }

    function checkIsFinished(lastStop) {

        if (createdStops.length === 0) {
            return false;
        }

        let lastStopFormatted = new Date(`${formatDate(lastStop.toDate)}T${formatFullTime(lastStop.toDate)}`);
        let originalStopFormatted = new Date(`${formatDate(originalMachineStop.toDateTimeZone)}T${formatFullTime(originalMachineStop.toDateTimeZone)}`);

        if (lastStopFormatted.getTime() === originalStopFormatted.getTime()) {
            return true;
        }

        return false;
    }

    function checkIsCurrentEndTimeStopEndTime() {
        if (createdStops.length === 0) {
            return false;
        }
    
        if (!currentStop || !toDateRef.current || !toTimeRef.current) {
            return false;
        }

        if (isNaN(new Date(getDateTimeSV(toDateRef.current.value, toTimeRef.current.value)))){
            return false;
        }
    
        if (isNaN(new Date(`${toDateRef.current.value}T${formatFullTime(currentStop.toDate)}`))) {
            return false;
        }

        if (isNaN(new Date(`${formatDate(currentStop.toDate)}T${toTimeRef.current.value}`))) {
            return false;
        }
        
        let currentStopFormatted = new Date(getDateTimeSV(toDateRef.current.value, toTimeRef.current.value));

        let currentStopFormatted00 = new Date(`${formatDate(currentStopFormatted)}T${formatTime(currentStopFormatted)}:00`);
        let originalStopFormatted = new Date(`${formatDate(originalMachineStop.toDateTimeZone)}T${formatTime(originalMachineStop.toDateTimeZone)}`);
        if (currentStopFormatted00.getTime() === originalStopFormatted.getTime()) {
            return true;
        }
        console.log("return false");
        return false;
    }

    function checkIsCurrentStopOriginalStop() {

        if (!currentStop || !toDateRef.current || !toTimeRef.current) {
            return false;
        }

        let currentStopFormatted = new Date(getDateTimeSV(toDateRef.current.value, toTimeRef.current.value));

        // console.log ("formatDate(currentStopFormatted)", formatDate(currentStopFormatted));
        // console.log ("formatTime(currentStopFormatted)", formatTime(currentStopFormatted));
        // console.log ("formatDate(currentStop.fromDate)", formatDate(currentStop.fromDate));
        // console.log ("formatTime(currentStop.fromDate)", formatTime(currentStop.fromDate));

        // console.log ("formatDate(originalMachineStop.fromDate)", formatDate(originalMachineStop.fromDateTimeZone));
        // console.log ("formatTime(originalMachineStop.fromDate)", formatTime(originalMachineStop.fromDateTimeZone));
        // console.log ("formatDate(originalMachineStop.toDate)", formatDate(originalMachineStop.toDateTimeZone));
        // console.log ("formatTime(originalMachineStop.toDate)", formatTime(originalMachineStop.toDateTimeZone));

        //If origanal stop is same as the new stop
        if ((formatDate(currentStop.fromDate) === formatDate(originalMachineStop.fromDateTimeZone)) &&
            (formatTime(currentStop.fromDate) === formatTime(originalMachineStop.fromDateTimeZone)) &&
            (formatDate(currentStopFormatted) === formatDate(originalMachineStop.toDateTimeZone)) &&
            (formatTime(currentStopFormatted) === formatTime(originalMachineStop.toDateTimeZone))) {
            console.log("currentStop.fromDate", currentStop.fromDate);
            console.log("currentStopFormatted", currentStopFormatted);
            console.log("originalMachineStop.fromDateTimeZone", originalMachineStop.fromDateTimeZone);
            console.log("originalMachineStop.toDateTimeZone", originalMachineStop.toDateTimeZone);
            console.log ("orig = curr");    
            return true;
        }

        //If start date is same as end date
        if ((formatDate(currentStopFormatted) === formatDate(currentStop.fromDateTimeZone)) &&
            (formatTime(currentStopFormatted)=== formatTime(currentStop.fromDateTimeZone))) {
            console.log("currentStopFormatted", currentStopFormatted);
            console.log("currentStop.fromDateTimeZone", formatDate(currentStop.fromDateTimeZone));
            console.log ("start = end");   
            return true;
        }

        // If end time is before start time
        if (secondsDiff(currentStopFormatted, new Date(`${formatDate(currentStop.fromDateTimeZone)}T${formatTime(currentStop.fromDateTimeZone)}`)) <= 0){
            console.log("currentStopFormatted", currentStopFormatted);
            console.log("currentStop.fromDateTimeZone", new Date(`${formatDate(currentStop.fromDateTimeZone)}T${formatTime(currentStop.fromDateTimeZone)}`));
            console.log ("start > end");   
            return  true;
        }

        return false;
    }

    function handleSave() {
        setOpenBackdrop(true);
        let splittedStops = { splittedStops: createdStops };
        putMachineStopSplit(splittedStops, companyKey, account.username).then((response) => {
            setOpenBackdrop(false);
            if (response && response.activityTriggered) {
                pushNotification(<FormattedMessage {...messages.activityTriggered} />);
            }
            navigate(-1); //navigate("/machines/" + currentStop.machineId);
        }).catch((error) => {
            console.log(error);
            setOpenBackdrop(false);
            pushErrorMessage(`Could not save splitted stops.`);
        });
    }

    function handleCancel() {
        navigate(-1);
    }


    function handleReset() {
        setCreatedStops([]);
        setCurrentStop(originalMachineStop);
        fromDateRef.current.value = formatDateLocalized(originalMachineStop.fromDateTimeZone);
        fromTimeRef.current.value = formatTimeLocalized(originalMachineStop.fromDateTimeZone);
        toDateRef.current.value = formatDateLocalized(originalMachineStop.toDateTimeZone);
        toTimeRef.current.value = formatTimeLocalized(originalMachineStop.toDateTimeZone);
        setIsValid(false);
        setIsFinished(false);
        setIsCurrentEndTimeStopEndTime(false);
    }

    if (machineStopQuery.isLoading) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="73vh">
                <CircularProgress color="secondary" />
            </Box>
        )
    }

    if (machineStopQuery.isError) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="73vh">
                <Typography variant="h5" color="error">Error: {machineStopQuery.error.message}</Typography>
            </Box>
        )
    }

    if (stopReasonCodesQuery.isLoading) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="73vh">
                <CircularProgress color="secondary" />
            </Box>
        )
    }

    if (stopReasonCodesQuery.isError) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="73vh">
                <Typography variant="h5" color="error">Error: {stopReasonCodesQuery.error.message}</Typography>
            </Box>
        )
    }

    return <Box sx={{
        p: 1,
        m: 1
    }}>

        {originalMachineStop &&
            <>
                <Box sx={{ backgroundColor: colors.primary[700], padding: 2, marginTop: '20px' }}>
                    <FormattedMessage {...messages.originalStop} />
                </Box>
                <Box sx={{ p: 2, display: 'grid', gridAutoColumns: '1fr', gap: 1, backgroundColor: colors.primary[400], marginBottom: '20px' }}>
                    <Box sx={{ gridRow: '1', gridColumn: '1' }}>
                        <FormattedMessage {...messages.machineName} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '2' }}>
                        <FormattedMessage {...messages.fromDate} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '3' }}>
                        <FormattedMessage {...messages.fromTime} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '4' }}>
                        <FormattedMessage {...messages.toDate} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '5' }}>
                        <FormattedMessage {...messages.toTime} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: 'span 2' }}>
                        <FormattedMessage {...messages.stopReason} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: 'span 2' }}>
                        <FormattedMessage {...messages.comment} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '10' }}>

                    </Box>

                    <Box sx={{ gridColumn: '1' }}>
                        {originalMachineStop.machineName}
                    </Box>
                    <Box sx={{ gridColumn: '2' }}>
                        {formatDateLocalized(originalMachineStop.fromDateTimeZone)}
                    </Box>
                    <Box sx={{ gridColumn: '3' }}>
                        {formatFullTimeLocalized(originalMachineStop.fromDateTimeZone)}
                    </Box>
                    <Box sx={{ gridColumn: '4' }}>
                        {formatDateLocalized(originalMachineStop.toDateTimeZone)}
                    </Box>
                    <Box sx={{ gridColumn: '5' }}>
                        {formatFullTimeLocalized(originalMachineStop.toDateTimeZone)}
                    </Box>
                    <Box sx={{ gridColumn: 'span 2' }}>
                        {stopReasonCodesQuery.data.find(sr => sr.id === originalMachineStop.stopReasonCodeId)?.name}
                    </Box>
                    <Box sx={{ gridColumn: 'span 2' }}>
                        {originalMachineStop.comment}
                    </Box>
                    <Box sx={{ gridColumn: '10' }}>

                    </Box>
                </Box>
            </>}


        {currentStop &&
            <>
                <Box sx={{ backgroundColor: colors.primary[700], p: 2 }}>
                    <FormattedMessage {...messages.splitStop} />
                </Box>
                <Box sx={{ p: 2, display: 'grid', gridAutoColumns: '1fr', gap: 1, backgroundColor: colors.primary[400] }}>
                    <Box sx={{ gridRow: '1', gridColumn: '1' }}>
                        <FormattedMessage {...messages.machineName} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '2' }}>
                        <FormattedMessage {...messages.fromDate} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '3' }}>
                        <FormattedMessage {...messages.fromTime} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '4' }}>
                        <FormattedMessage {...messages.toDate} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '5' }}>
                        <FormattedMessage {...messages.toTime} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: 'span 2' }}>
                        <FormattedMessage {...messages.stopReason} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: 'span 2' }}>
                        <FormattedMessage {...messages.comment} />
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: '10' }}>

                    </Box>

                    <Box sx={{ gridRow: '2', gridColumn: '1' }}>
                        <AxionControls.AxionTextBoxNoLabelReadOnly
                            name="machineName"
                            value={currentStop.machineName}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ gridRow: '2', gridColumn: '2' }}>
                        <AxionControls.AxionTextBoxNoLabelReadOnly
                            value={formatDateLocalized(currentStop.fromDateTimeZone)}
                            inputRef={fromDateRef}
                        />
                    </Box>
                    <Box sx={{ gridRow: '2', gridColumn: '3' }}>
                        <AxionControls.AxionTextBoxNoLabelReadOnly
                            value={formatTimeLocalized(currentStop.fromDateTimeZone)}
                            inputRef={fromTimeRef}
                        />
                    </Box>
                    <Box sx={{ gridRow: '2', gridColumn: '4' }}>
                        <AxionControls.AxionTextBoxNoLabel
                            name="toDateOnly"
                            value={formatDateLocalized(currentStop.toDateTimeZone)}
                            onChange={handleChange}
                            inputRef={toDateRef}
                        />
                    </Box>
                    <Box sx={{ gridRow: '2', gridColumn: '5' }}>
                        <AxionControls.AxionTextBoxNoLabel
                            name="toTimeOnly"
                            value={formatTimeLocalized(currentStop.toDateTimeZone)}
                            onChange={handleChange}
                            inputRef={toTimeRef}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{ color: "#000" }}><DoneIcon sx={{ color: "green", display: IsCurrentEndTimeStopEndTime ? "block" : "none" }} /> </InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box sx={{ gridRow: '2', gridColumn: 'span 2' }}>
                        <AxionControls.AxionSelectNoLabel
                            options={filteredStopReasonCodes.map((item) => {
                                return {
                                    id: item.id,
                                    title: item.name
                                }
                            })}
                            label="Stop Reason"
                            name="stopReasonCodeId"
                            value={currentStop.stopReasonCodeId}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ gridRow: '2', gridColumn: 'span 2' }}>
                        <AxionControls.AxionTextBoxNoLabel
                            name="comment"
                            value={currentStop.comment}
                            onChange={handleChange} />
                    </Box>
                    <Box sx={{ gridRow: '2', gridColumn: '10', paddingTop: '5px' }}>
                        <Button
                            disabled={!isValid || isFinished || IsCurrentStopOriginalStop}
                            text={<FormattedMessage {...messages.add} />}
                            onClick={handleAdd}
                        />
                    </Box>
                </Box>


                {createdStops && createdStops.length > 0 &&
                    <>
                        <Box sx={{ backgroundColor: colors.primary[700], padding: 2, marginTop: '20px' }}>
                            <FormattedMessage {...messages.splittedStop} />
                        </Box>
                        <Box sx={{ p: 2, display: 'grid', gridAutoColumns: '1fr', gap: 1, backgroundColor: colors.primary[400] }}>
                            <Box sx={{ gridRow: '1', gridColumn: '1' }}>
                                <FormattedMessage {...messages.machineName} />
                            </Box>
                            <Box sx={{ gridRow: '1', gridColumn: '2' }}>
                                <FormattedMessage {...messages.fromDate} />
                            </Box>
                            <Box sx={{ gridRow: '1', gridColumn: '3' }}>
                                <FormattedMessage {...messages.fromTime} />
                            </Box>
                            <Box sx={{ gridRow: '1', gridColumn: '4' }}>
                                <FormattedMessage {...messages.toDate} />
                            </Box>
                            <Box sx={{ gridRow: '1', gridColumn: '5' }}>
                                <FormattedMessage {...messages.toTime} />
                            </Box>
                            <Box sx={{ gridRow: '1', gridColumn: 'span 2' }}>
                                <FormattedMessage {...messages.stopReason} />
                            </Box>
                            <Box sx={{ gridRow: '1', gridColumn: 'span 2' }}>
                                <FormattedMessage {...messages.comment} />
                            </Box>
                            <Box sx={{ gridRow: '1', gridColumn: '10' }}>
                                <FormattedMessage {...messages.type} />
                            </Box>

                            <SplitMachineStopRow createdStops={createdStops} stopReasonCodes={stopReasonCodesQuery.data} />
                        </Box>
                        <Box sx={{ flexDirection: 'row', paddingLeft: 2, paddingBottom: 2, backgroundColor: colors.primary[400] }}>
                            <Button
                                variant="contained"
                                text={<FormattedMessage {...messages.save} />}
                                disabled={!isFinished}
                                sx={{
                                    marginTop: 2,
                                    marginRight: 2
                                }}
                                onClick={handleSave} />
                            <Button
                                variant="contained"
                                text={<FormattedMessage {...messages.reset} />}
                                sx={{
                                    marginTop: 2,
                                    marginRight: 2
                                }}
                                onClick={handleReset} />
                            <Button
                                variant="contained"
                                text={<FormattedMessage {...messages.cancel} />}
                                sx={{
                                    marginTop: 2
                                }}
                                onClick={handleCancel} />
                        </Box>
                    </>
                }
                {createdStops && createdStops.length === 0 &&
                    <Box sx={{ flexDirection: 'row', paddingLeft: 2, paddingBottom: 2, backgroundColor: colors.primary[400] }}>
                        <Button
                            variant="contained"
                            text={<FormattedMessage {...messages.cancel} />}
                            sx={{
                                marginTop: 2,
                            }}
                            onClick={handleCancel} />
                    </Box>}
            </>
        }
        <AxionControls.AxionBackdrop open={openBackdrop} />
    </Box>
}