import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { tokens } from "../../../assets/theme";
import { useTheme, Avatar, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import OeeSummarize from "./oeeSummarize";

function CustomFooterTotalComponent(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Grid container sx={{ 
      backgroundColor: colors.primary[700], 
      marginTop: 1 
      }}> 
      <Grid item md={12} >
        <Box sx={{ marginTop: "20px", marginBottom: "10px" }}>
          <Stack 
            direction="row" 
            spacing={2} 
            justifyContent="flex-end" 
            marginLeft={2}
            marginRight={2}>
            <Avatar 
              sx={{ 
                backgroundColor: colors.dashbordGreen[600], 
                color:"#fff", 
                height:"70px", 
                width:"70px", 
                fontSize: 26 }}>
                  {props.machinesRunning}
            </Avatar>
            <Avatar 
              sx={{ 
                  backgroundColor: colors.redAccent[600], 
                  color:"#fff", 
                  height:"70px", 
                  width:"70px", 
                  fontSize: 26 }}>
                    {props.machinesStopped} 
            </Avatar>
            <Avatar 
              sx={{ 
                backgroundColor: colors.gray[600], 
                color:"#fff", 
                height:"70px", 
                width:"70px", 
                fontSize: 26 }}>
                  {props.machinesPlannedStop} 
            </Avatar>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

CustomFooterTotalComponent.propTypes = {
  total: PropTypes.number
};

export { CustomFooterTotalComponent };
