
/**
 * @file activitiesGrid.jsx
 * @desc This file contains the ActivitiesGrid component, which is responsible for rendering a grid of activities.
 * The component fetches activities data and displays it in a DataGrid component from the @mui/x-data-grid library.
 * It also handles opening and closing activity details in a popup, updating activity status, and deleting activities.
 */
import React from "react";
import { useState, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { Box, useTheme, CircularProgress,  IconButton, Typography } from '@mui/material';
import { tokens } from "../../../assets/theme";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteIcon from "@mui/icons-material/Delete";
import Popup from "../../../components/Popup";
import AxionControls from "../../../components/controls/AxionControls";
import ActivityViewer from "./activityViewer";
import { pushErrorMessage } from "../../../utils/notifications";
import { fetchAllStatuses, updateActivity } from "../hooks/fetchers";
import moment from 'moment-timezone';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { Toolbar } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from "@mui/material";
import Controls from "../../../components/controls/Contols";
import { useQuery } from "@tanstack/react-query";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import useFeatureFlags, { isFeatureEnabled } from "../../../hooks/useFeatureFlags";
import Tooltip from '@mui/material/Tooltip';

export default function ActivitiesGrid(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const apiRef = useGridApiRef();
    const [openPopup, setOpenPopup] = useState(false);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
    const [selectedActivity, setSelectedActivity] = React.useState(null);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const columnOrderLocalStorageName = 'activities-columnOrder';
    const columnWidthsLocalStorageName = 'activities-columnWidths';

    // Load persisted column order, widths, and sort order on mount
    function initColumnsFromLocalStorage(columns) {
      let columnsToFix = columns;
      // Load column order
      const savedColumnOrder = localStorage.getItem(columnOrderLocalStorageName);
      if (savedColumnOrder) {
        const orderArray = JSON.parse(savedColumnOrder);
        columnsToFix = reorderColumns(orderArray, columnsToFix);
      }

      // Load column widths
      const savedWidths = localStorage.getItem(columnWidthsLocalStorageName);
      if (savedWidths) {
          const widthMap = JSON.parse(savedWidths);
          columnsToFix = applyColumnWidths(widthMap, columnsToFix);
        }
      return columnsToFix;
    };
    
    // Reorder columns based on saved order
    const reorderColumns = (orderArray, columnsToFix) => {
      const reorderedColumns = orderArray.map((field) =>
          columnsToFix.find((col) => col.field === field)
      );
      reorderedColumns.push(...columnsToFix.filter((col) => !reorderedColumns.includes(col)));
      return reorderedColumns;
    };

    // Apply saved column widths
    const applyColumnWidths = (widthMap, columnsToFix) => {
      const updatedColumns = columnsToFix.map((col) => ({
        ...col,
        width: widthMap[col.field] || col.width, 
        flex: widthMap[col.field] !== undefined ? undefined : col.flex, 
      }));
      return updatedColumns;
    };

    // Handle column order change
    const handleColumnOrderChange = () => {
      if (!apiRef.current) {
        return;
      }
      const orderedColumns = apiRef.current.getVisibleColumns();
      const newColumnOrder = orderedColumns.map((col) => col.field);
      localStorage.setItem(columnOrderLocalStorageName, JSON.stringify(newColumnOrder));
    };

    // Handle column resize
    const handleColumnResize = (params) => {
      const { colDef, width } = params;
      const newWidths = JSON.parse(localStorage.getItem(columnWidthsLocalStorageName)) || {};
      newWidths[colDef.field] = width;
      localStorage.setItem(columnWidthsLocalStorageName, JSON.stringify(newWidths));
    };

    const statusQuery = useQuery(
      ["statuses"],
      () => fetchAllStatuses(),
      {
        staleTime: 120000
      }
    );

    const featuresQuery = useFeatureFlags();

    if(featuresQuery.isLoading) {
      return <></>;
    }

    const handleViewActivityClick = (id) => {
      
        setSelectedActivity(id);
        setOpenPopup(true);
      }

    const handleCloseActivity = () => {
      setShowBackdrop(true);
      // Use a sepearate status for re-openend activities
      const reopenedActivity = selectedActivity.activityStatus.id === 4;

      selectedActivity.activityStatus.id = reopenedActivity ? 6 : 3
      selectedActivity.activityStatus.text = reopenedActivity ? "Reopened and completed" : "Completed"
      if(reopenedActivity) {
        selectedActivity.initiallyClosed = selectedActivity.closed;
      }
      
      selectedActivity.closed = moment.tz(moment(),"Europe/Stockholm");
      selectedActivity.closedBy = account.username;
      selectedActivity.updated = moment.tz(moment(),"Europe/Stockholm");
      selectedActivity.updatedBy = account.username;
      selectedActivity.activityStatusId = 3;
      updateActivity(selectedActivity).then(() => {
        props.activitiesResult.refetch();
        setShowBackdrop(false);
        setOpenPopup(false);
        
      })
      .catch((error) => {
        console.log(error);
        setShowBackdrop(false);
        pushErrorMessage(`Cannot close activity`);
      });
    }

    const handleSearchChange = (e) => {
      props.setActivityFilter(e.target.value);
    };
 
    let columns = [
      { 
        field: "machineName", 
        headerName: <FormattedMessage {...messages.machineName} />,
        flex: 0.3,
        minWidth: 100,
        valueGetter: ({ row }) => row.machine.machineName
      },
      { 
        field: "machineTypeName", 
        headerName: <FormattedMessage {...messages.machineType} />,
        flex: 0.3,
        minWidth: 100,
        valueGetter: ({ row }) => row.machine.machineType.machineTypeName
      },
      { 
        field: "toolName", 
        headerName: <FormattedMessage {...messages.toolName} />,
        flex: 0.3,
        minWidth: 100,
        valueGetter: ({ row }) => row.tool?.name
      },
      { 
        field: "itemNo", 
        headerName: <FormattedMessage {...messages.itemName} />,
        flex: 0.3,
        minWidth: 100,
        valueGetter: ({ row }) => row.productionItem?.itemNo
      },
      { 
        field: "title", 
        headerName: <FormattedMessage {...messages.activityName} />,
        flex: 0.3,
        maxWidht: 150,
      },
      { 
        field: "created", 
        headerName: <FormattedMessage {...messages.created} />,
        valueGetter: ({ value }) => value && new Date(value).toLocaleString('sv-SE'),
        flex: 0.3,
        minWidth: 100,
       
     
      },
      { 
        field: "text", 
        headerName:<FormattedMessage {...messages.status} />,
        minWidth: 300,
        flex: 0.5,
        valueGetter: ({ row }) => row.activityStatus.text,
        renderCell: ({ row }) => {
                return (
                    <Box
                        width="100%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                                row.activityStatus.text === "Not started"
                                ? colors.gray[600]
                                : row.activityStatus.text === "Not Completed"
                                ? colors.redAccent[600]
                                : row.activityStatus.text === "Cancelled"
                                ? colors.primary[600]
                                : colors.dashbordGreen[600]
                        }
                        borderRadius="4px"
                    >
                        {/* {a === "admin" && <AdminPanelSettingsOutlinedIcon />}
                        {a === "manager" && <SecurityOutlinedIcon />}
                        {<kbd></kbd> === "user" && <LockOpenOutlinedIcon />} */}
                        { row.activityStatus.id === 6 && 
                          <Tooltip title={<Typography style={{ whiteSpace: 'pre-line' }} fontSize="14px">Autoclosed at: {new Date(row.initiallyClosed).toLocaleString('sv-SE')}</Typography>}>
                            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
                                {row.activityStatus.text}
                            </Typography>
                          </Tooltip>
                        }
                        { row.activityStatus.id !== 6 && 
                          <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
                              {row.activityStatus.text}
                          </Typography>
                        }
                    </Box>
                );
            },

      },
      { 
        field: "closed", 
        headerName:<FormattedMessage {...messages.closed} />,
        valueGetter: ({ value }) => value && new Date(value).toLocaleString('sv-SE'),
        minWidth: 150,
        flex: 0.5,
      },
      { 
        field: "timeLeft", 
        headerName:<FormattedMessage {...messages.timeLeft} />,
        valueGetter: (params) => {
          if(params.row.closed === null){ 
          const language = "sv-SE";
          const timeZone =  "Europe/Amsterdam"
          const endDate =  moment(params.row.maxClosedDate);
          const timeLeft = endDate.diff(moment(), 'hours', true);
          return timeLeft.toFixed(2) >= 1 ? timeLeft.toFixed(2) + " hour(s)" : (timeLeft.toFixed(2) * 60).toFixed() + " min";
          }
        },
          
         
        minWidth: 150,
        flex: 0.5,
      },
      { 
        field: "late", 
        headerName:<FormattedMessage {...messages.late} />,
        valueGetter: (params) => {
          if(params.row.closed === null){ 
          const startDate =  moment(params.row.created);
          const endDate =  moment(params.row.maxClosedDate);
          const timeLeft = endDate.diff(moment(), 'hours', true);
          const duration = endDate.diff(startDate,'hours', true)
          return timeLeft < (duration/2) ? 
          "late" : null;
          }
        },
        renderCell: (params) => {
          const language = "sv-SE";
          const timeZone =  "Europe/Amsterdam"
          const startDate =  moment(params.row.created);
          const endDate =  moment(params.row.maxClosedDate);
          const timeLeft = endDate.diff(moment(), 'hours', true);
          const duration = endDate.diff(startDate,'hours', true)
          if(params.row.closed !== null){ 

          return (null)
          
          }else
          {
            return (timeLeft < (duration/2) ? 
            <Box
            width="100%"
            m="0 auto"
            p="5px"
            ml={2}
            display="flex"
            justifyContent="center"
            backgroundColor={colors.yellow[600]}
            borderRadius="4px"
            >
             <HistoryToggleOffOutlinedIcon/>
            </Box>
            : <></>
          )
          }
        },
        maxWidth: 90,
        flex: 0.5,
      },
      { 
        field: "closedBy", 
        headerName:<FormattedMessage {...messages.closedBy} />,
        minWidth: 150,
        flex: 0.5,
      },
      { 
        field: "maxClosedDate", 
        headerName:<FormattedMessage {...messages.autocloseAt} />,
        valueGetter: ({ value }) => value && new Date(value).toLocaleString('sv-SE'),
        minWidth: 150,
        flex: 0.5,
      },
      {
        field:"edit", 
        headerName:"",
        renderCell:(params) => {
            return(
                <>
                {params.row.closed === null || (isFeatureEnabled(featuresQuery, "CanEditClosedActivities") && params.row.activityStatus.text === "Not Completed") ? 
                <>
                    <IconButton
                        arial-label="Late activity"
                        size="small"
                        sx={{
                            "&:hover": {
                            backgroundColor: colors.primary[500],
                            },
                            backgroundColor: colors.primary[700],
                        }}
                        onClick={() => {
                            handleViewActivityClick(params.row)
                        }}
                    >
                        <RemoveRedEyeOutlinedIcon fontSize="inherit" />
                    </IconButton>
                </>
                : null}
                </>
            )
        },
        width: 90,
      },
      ];
  
    if (props.activitiesResult.isLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      );
    }

    columns = initColumnsFromLocalStorage(columns);
  
    return (
      <Box >
        <Box 
              m="0px 0 0 0"
              height="84vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                },
                "& .name.column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-cellContent": {
                  fontSize: "13px",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.primary[600],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.primary[600],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.gray[100]} !important`,
                },
                "& .super-app-theme--11": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .super-app-theme--undefined-stop": {
                  bgcolor: `${colors.redAccent[500]} !important`,
                },
                "& .MuiDataGrid-columnHeader": {
                  fontSize: "15px",
                },
              }}
            >
              <Toolbar sx={{ backgroundColor: colors.primary[700], marginBottom: 1, paddingTop: 2, paddingBottom: 2 }}>
                    <Controls.Input
                        label={<FormattedMessage {...messages.search} />}
                        sx={{ width: "50%" }}
                        InputProps={{
                            size: "small",
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleSearchChange}
                    />
                    { !props.hideStatusFilter && statusQuery.data && 
                      <Box sx={{ width: 400, marginLeft: 2 }}>
                        <AxionControls.AxionSelect
                            label={<FormattedMessage {...messages.status} />}
                            value={props.statusFilter}
                            noneText={<FormattedMessage {...messages.allOption} />}
                            sx={{ width: "200px" }}
                            options={statusQuery.data.map((status) => ({
                              id: status.id,
                              title: status.text,
                            }))}
                            onChange={(e) => props.setStatusFilter(e.target.value)}

                        />
                      </Box>
                    }
                </Toolbar>
              { props.filteredResult && <DataGridPro
                apiRef={apiRef}
                onColumnResize={handleColumnResize}
                onColumnOrderChange={handleColumnOrderChange}
                rows={props.filteredResult}
                getRowId={ (row) => row.id }
                columns={columns}
                rowHeight={38}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                initialState={props.InitialState}
              /> }
              { (props.activitiesResult.isLoading) && <CircularProgress color="secondary" />}
            </Box>

            <Popup
             openPopup={openPopup}
             setOpenPopup={setOpenPopup}
             maxWidht="sm"
        title="Activity"
      >
        <ActivityViewer
          
          handleCloseActivity={handleCloseActivity}
          activity={selectedActivity}
          setOpenPopup={setOpenPopup}
          setShowBackdrop = {setShowBackdrop}
          activityResult= {props.activitiesResult}
          
        />
      </Popup>
      <AxionControls.AxionBackdrop open={showBackdrop} />
        </Box>
    )
}