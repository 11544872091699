import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from "@mui/material";

export default function CircularProgressWithLabel(props) {
    const { value, size, circleColor, text, fontSize = 20 } = props;
    const actualValue = value;
    let circleValue = value;
    if (value > 100) {
        circleValue = 100;
    }
    if (value < 0) {
        circleValue = 0;
    }
    props = { ...props, value: circleValue };
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        
        <CircularProgress
        variant="determinate"
        value={100}
        size={size ?? 100} 
        thickness={5} 
        sx={{
          marginTop: 1, 
          marginLeft: 1, 
          color: '#141b2d',
        }}
        />
        <CircularProgress 
            thickness={5} 
            size={size ?? 100} 
            color="secondary"  
            variant="determinate" 
            disableShrink
            {...props} 
            sx={{
                marginTop: 1, 
                marginLeft: 1, 
                color: circleColor,
                position: 'absolute',
                left: 0,
            }} 
        />
        
        <Box
          sx={{
            top: 10,
            left: 10,
            bottom: 2,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography 
            variant="caption" 
            component="div" 
            color="text.secondary" 
            sx={{ 
                fontSize: {fontSize}, 
                textAlign: 'center', 
                lineHeight: 1.2 
            }}>
                {text}<br />{`${Math.round(actualValue)} %`}
          </Typography>
        </Box>
      </Box>
    );
  }