import React from "react";
import messages from "../messages";
import { useIntl } from "react-intl";
import { Stack } from "@mui/material";
import CircularProgressWithLabel from "../../../components/controls/CircularProgressWithLabel";

export default function OeeSummarize(props) {
    const intl = useIntl();

    return (
        <Stack direction="row" spacing={1} justifyContent="flex-end" marginRight={2}>
          <CircularProgressWithLabel text={intl.formatMessage(messages.tFooter)} value={props.totalT} circleColor={props.totalTColor} />  
          <CircularProgressWithLabel text={intl.formatMessage(messages.aFooter)} value={props.totalA} circleColor={props.totalAColor} />  
          <CircularProgressWithLabel text={intl.formatMessage(messages.kFooter)} value={props.totalK} circleColor={props.totalKColor} />  
          <CircularProgressWithLabel text={intl.formatMessage(messages.takFooter)} value={props.total} circleColor={props.totalColor }/> 
        </Stack>
    )
}