import React from 'react';
import { Card, CardContent, Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../assets/theme";
import CircularProgressWithLabel from '../../components/controls/CircularProgressWithLabel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Error from '@mui/icons-material/Error';
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export default function AxionMachineCard(props) {
    const { id, key, item, fields } = props;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
      };

    return (
        <Card 
            ref={setNodeRef} 
            style={style} 
            {...attributes} 
            {...listeners}
            sx={{ 
                backgroundColor: colors.primary[600], 
                width: 325, 
                borderRadius: "10px" 
            }}>
            <CardContent>
                <Box
                        width="80%"
                        m="10px auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            item.machineStatus === "Running"
                            ? colors.dashbordGreen[600]
                            : item.machineStatus === "Offline"
                            ? colors.yellow[500]
                            : colors.redAccent[500]
                        }
                        borderRadius="10px"
                    >
                    <Typography sx={{ display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        textAlign: "center" }} 
                        variant="h1" component="div">
                        { item.machineName } { item.machineStatus === "Running" ? <CheckCircle fontSize='large' /> : <Error fontSize='large' /> }
                    </Typography>
                </Box>
                { item.machineStatus == "Stopped" && (
                <Typography sx={{ textAlign: 'center', color: colors.primary[100] }} variant="h3" component="div">
                    { item.currentStop } <br />
                    { item.currentStopTime } min<br /><br />
                </Typography>
                )}
                { item.machineStatus !== "Stopped" && (
                <Typography sx={{ textAlign: 'center', color: colors.primary[100] }} variant="h3" component="div">
                    <br /><br /><br />
                </Typography>
                )}
                { fields.includes("Last CT") && (
                    <Box 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap={2} 
                        sx={{ width: "100%" }} >
                        <Box sx={{ textAlign: "right",  width: "25%"  }}>
                            <Typography sx={{ color: colors.primary[100] }} variant="h4" component="div">
                                Last CT:
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: "right",  width: "25%" }}>
                            <Typography sx={{ color: colors.primary[100] }} variant="h4" component="div">
                            { Number.parseFloat(item.lastCycleTime).toFixed(2) } s
                            </Typography>
                        </Box>
                    </Box>
                )}
                { fields.includes("Avg CT") && (
                    <Box 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap={2} 
                        sx={{ width: "100%" }} >
                        <Box sx={{ textAlign: "right",  width: "25%"  }}>
                            <Typography sx={{ color: colors.primary[100] }} variant="h4" component="div">
                                Avg CT:
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: "right",  width: "25%" }}>
                            <Typography sx={{ color: colors.primary[100] }} variant="h4" component="div">
                                { Number.parseFloat(item.avgCycleTime).toFixed(2) } s
                            </Typography>
                        </Box>
                    </Box>
                )}
                { fields.includes("Ideal CT") && (
                    <Box 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap={2} 
                        sx={{ width: "100%" }} >
                        <Box sx={{ textAlign: "right",  width: "25%"  }}>
                            <Typography sx={{ color: colors.primary[100] }} variant="h4" component="div">
                                Ideal CT:
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: "right",  width: "25%" }}>
                            <Typography sx={{ color: colors.primary[100] }} variant="h4" component="div">
                                { Number.parseFloat(item.idealCycleTimeActive).toFixed(2) } s
                            </Typography>
                        </Box>
                    </Box>
                )}
                { fields.includes("TAK") && (
                <Box width="80%"
                        m="20px auto"
                        p="5px"
                        display="flex"
                        justifyContent="center">
                    <CircularProgressWithLabel size={150} fontSize={24} value={ item.tak } circleColor={ colors.primary[300] } text={<FormattedMessage {...messages.oee} />} />
                </Box>
                )}
                { fields.includes("T") && (
                <Box width="80%"
                        m="20px auto"
                        p="5px"
                        display="flex"
                        justifyContent="center">
                    <CircularProgressWithLabel size={150} fontSize={24} value={ item.t } circleColor={ colors.primary[300] } text={<FormattedMessage {...messages.a} />} />
                </Box>
                )}
                { fields.includes("A") && (
                <Box width="80%"
                        m="20px auto"
                        p="5px"
                        display="flex"
                        justifyContent="center">
                    <CircularProgressWithLabel size={150} fontSize={24} value={ item.a } circleColor={ colors.primary[300] } text={<FormattedMessage {...messages.p} />} />
                </Box>
                )}
                { fields.includes("K") && (
                <Box width="80%"
                        m="20px auto"
                        p="5px"
                        display="flex"
                        justifyContent="center">
                    <CircularProgressWithLabel size={150} value={ item.k } circleColor={ colors.primary[300] } text={<FormattedMessage {...messages.q} />}/>
                </Box>
                )}
            </CardContent>
          </Card>
    )
}