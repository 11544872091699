import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import  Home  from "./pages/Home";
import Planning from "./scenes/planning";
import TEst from "./scenes/test";
import OeeGrid from "./pages/OeeGrid";
import Tasks from "./scenes/Tasks";
import Lab from "./pages/Lab";
import Machine from "./pages/Machine";
import Material from "./pages/Material";
import Instructions from "./pages/Instructions";
import Drawings from "./pages/Drawings";
import File from "./pages/File";
import ViewInstructions from './features/instructions/components/viewInstruction';
import MachineDetailsPage from "./pages/MachineDetailsPage";
import StopReasonCodesPage from "./pages/StopReasonCodesPage";
import Activities from './pages/Activities';
import Gantt from "./pages/Gantt";
import SplitMachineStopPage from "./pages/SplitMachineStopPage";
import CommingSoonPage from "./pages/CommingSoonPage";
import SettingsPage from "./pages/SettingsPage";
import EmbeddedReportsPage from "./pages/EmbeddedReportsPage";
import MachineDashboardPage from "./pages/MachineDashboardPage";
import ToolsPage from "./pages/Tools";
import ProductionItemsPage from "./pages/ProductionItemsPage";
import ClearLocalStoragePage from "./pages/ClearLocalStoragePage";
import MachineTypesPage from "./pages/MachineTypesPage";
import MachineSettingsPage from "./pages/MachineSettingsPage";
import MachinesStatusPage from "./pages/MachinesStatusPage";

const AppRoutes = [
  {
    
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    index: true,
    
    element:<Home />
  },
  {
    path:"/planning",
    element:<Planning />
  },
  {
    path:"/machines",
    element:<Machine />
  },
  {
    path:"/machines/:id",
    element:<MachineDetailsPage />
  },
  {
    path:"/machines/:id/",
    element:<MachineDetailsPage />
  },
  {
    path:"/test",
    element:<TEst />
  },
  {
    path:"/oeegrid",
    element: <OeeGrid />
  },
  {
    path:"/tasks",
    element: <Tasks />
  },
  {
    path:"/lab",
    element: <Lab />
  },
  {
    path:"/material",
    element: <Material />
  },
  {
    path:"/gantt",
    element: <Gantt />
  },
  {
    path:"/stopreasoncodes",
    element: <StopReasonCodesPage />
  },
  {
    path:"/instructions",
    element: <Instructions />
  },
  {
    path:"/drawings",
    element: <Drawings />
  },
  {
    path:"/instructions/:id",
    element: <ViewInstructions />
    
  },
  {
    path:"/file",
    element: <File />
  },
  {
    path:"/machinestops/:id/split/",
    element:<SplitMachineStopPage />
  },
  {
    path:"/settings",
    element: <SettingsPage />
  },
  {
    path:"/embeddedreports",
    element: <EmbeddedReportsPage />
  },
  {
    path:"/commingsoon",
    element: <CommingSoonPage />
  },
  {
    path:"/Activities",
    element: <Activities />
  },
  {
    path:"/machineDashboard/:id",
    element: <MachineDashboardPage />
  },
  {
    path:"/tools",
    element: <ToolsPage />
  },
  {
    path:"/productionItems",
    element: <ProductionItemsPage />
  },
  {
    path:"/clearLocalStorage",
    element: <ClearLocalStoragePage />
  },
  {
    path:"/machineTypes",
    element:<MachineTypesPage />
  },
  {
    path:"/machinesStatus",
    element:<MachinesStatusPage />
  }
];

export default AppRoutes;

export const apiRoutes = {
  getGanttData: 'api/gantt/getGanttData/:companyKey'
};