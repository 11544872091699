import axios from "axios";

export const getAllActivities = async (companyKey, fromDate, toDate) => {
  try {
    const response = await axios.get(`api/job/activities/?companyKey=${companyKey}&fromDate=${fromDate}&toDate=${toDate}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAllActivitiesByCreatedDate = async (companyKey, fromDate, toDate) => {
  try {
    const response = await axios.get(`api/job/activities/byCreatedDate/?companyKey=${companyKey}&fromDate=${fromDate}&toDate=${toDate}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getOpenActivities = async (companyKey) => {
  try {
    const response = await axios.get(`api/job/activities/open/?companyKey=${companyKey}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};


export const getActivity = async (id) => {
  try {
    const response = await axios.get(`/api/job/activities/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAllActivitiesForMachine = async (machineId) => {
  try {
    const response = await axios.get(
      `/api/job/activities/machine/${machineId}`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getRecurringSchedules= async (companyKey) => {
    try {
      const response = await axios.get(`/api/job/schedulerecurring/?companyKey=${companyKey}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const getRecurringActivity = async (id) => {
    try {
      const response = await axios.get(`/api/job/schedulerecurring/?id=${id}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const fetchAllInstructions = async (companyKey) => {
    try {
      const response = await axios.get(`/api/instructions/?companyKey=${companyKey}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const fetchMachines = async (companyKey) => {
    const response = await axios.get(`api/machine/?companyKey=${companyKey}`);
    const machines = response.data;
    return machines;
};


export const deleteScheduleRecurring = async (id) => {
  try {
    const response = await axios.delete(`/api/job/schedulerecurring/${id}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateScheduleRecurring = async (scheduleRecurring) => {
  try {
    const response = await axios.put(`/api/job/schedulerecurring`, scheduleRecurring);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createScheduleRecurring = async (scheduleRecurring) => {
  try {
    const response = await axios.post(`/api/job/schedulerecurring`, scheduleRecurring);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const updateActivity = async (activity) => {
  try {
    const response = await axios.put(`/api/job/activities`, activity);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const fetchAllStatuses = async () => {
  try {
    const response = await axios.get(`/api/job/activitystatuses/`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

