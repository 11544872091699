import { defineMessages } from 'react-intl';

export const circularProgressWithLabelScope = 'axionoee.conrols.circularProgressWithLabel';

export default defineMessages({
  oee: {
    id: `${circularProgressWithLabelScope}.label.oee`,
    defaultMessage: 'OEE',
  },
  a: {
    id: `${circularProgressWithLabelScope}.label.a`,
    defaultMessage: 'A',
  },
  p: {
    id: `${circularProgressWithLabelScope}.label.p`,
    defaultMessage: 'P',
  },
  q: {
    id: `${circularProgressWithLabelScope}.label.q`,
    defaultMessage: 'Q',
  },
});