import React, { useEffect } from "react";
import Gantt from "./components/gantt";
import Popup from "../../components/Popup";
import { Box, FormControlLabel, Switch, Grid, Stack } from "@mui/material";
import { useGetGanttData } from "../../api/gantt";
import { FormattedMessage } from "react-intl";
import MachineStopEditDialog from "../machineDetails/components/machineStopEditDialog";
import AxionControls from "../../components/controls/AxionControls";
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { getStopReasonCodes } from "../stopReasonCodes/hooks/fetchers";
import { fetchMachineData, fetchMachineStop } from "../../fetchers/machine";
import { pushErrorMessage } from '../../utils/notifications';
import dayjs from "dayjs";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import DateFilter from "../machineDetails/components/DateFilter";
import { getTimespan } from "../../fetchers/timespan";
import messages from "./messages";
import { alpha, useTheme } from "@mui/material/styles";
import { tokens } from "../../assets/theme";
import { fetchCompany } from "../../fetchers/company";
import { getOpenActivities } from "../activities/hooks/fetchers";

function convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

export default function GanttIndex() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
  const navigate = useNavigate();
  
  const {data, isLoading, isFetching, isSuccess, refetch } = useGetGanttData(companyKey);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [machineStop, setMachineStop] = React.useState(null);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [filteredStopReasonCodes, setFilteredStopReasonCodes] = React.useState([]);
  const [filteredGanttData, setFilteredGanttData] = React.useState();
  
  const [timeSpan, setTimeSpan] = useLocalStorage("timespan", "currentshift");
  const [startDate, setStartDate] = useLocalStorage("startDate", dayjs());
  const [endDate, setEndDate] = useLocalStorage("endDate", dayjs());
  const [checked, setCheck] = useLocalStorage("checked", {
    running: false,
    onlyPlanned: false,
    onlyStopped: false,
  });

  const stopReasonCodesQuery = useQuery(
    ["stopReasonCodes", companyKey], 
    () => getStopReasonCodes(companyKey),
    {
        refetchInterval: 60000,
        staleTime: 60000
    }
  );

  const DatesForTimespanQuery = useQuery(
    ["datesForTimespan", timeSpan, companyKey],
    () => getTimespan(timeSpan, companyKey),
    {
        refetchInterval: 60000,
        staleTime: 60000
    }
  );

  const companyQuery = useQuery(
    ["company", companyKey],
    () => fetchCompany(companyKey),
    {
        refetchInterval: 300000,
        staleTime: 60000
    }
  );

  const openActivitiesQuery = useQuery(
    ["openActivities", companyKey],
    () => getOpenActivities(companyKey),
    {
      staleTime: 60000,
      refetchInterval: 60000,
    }
  );
  
  useEffect(() => {
    setFilteredStopReasonCodes(stopReasonCodesQuery.data);
  }, [stopReasonCodesQuery.data]);
  
  useEffect(() => {
    if (DatesForTimespanQuery.isFetched && timeSpan !== 'manual' && !DatesForTimespanQuery.isError) {
      // Temporary fix until we have a generinc handlign of timezones
      if(companyKey === 2 && DatesForTimespanQuery.data[0].fromDate !== null && DatesForTimespanQuery.data[0].toDate !== null && (timeSpan === '24h' || timeSpan === '72h' || timeSpan === 'week'))
      {
         setStartDate(convertTZ(DatesForTimespanQuery.data[0].fromDate, "America/New_York"));
         setEndDate(convertTZ(DatesForTimespanQuery.data[0].toDate, "America/New_York"));
      }
      else {
        setStartDate(DatesForTimespanQuery.data[0].fromDate);
        setEndDate(DatesForTimespanQuery.data[0].toDate);
      }
    }
  }, [DatesForTimespanQuery.data, DatesForTimespanQuery.isFetched, DatesForTimespanQuery.isError, timeSpan, companyKey]);

  useEffect(() => {
    setFilteredGanttData(data);
    filterRunning();
  }, [isSuccess, data]);

  useEffect(() => {
    if(filteredGanttData === undefined) { return; }
    filterRunning();
  }, [checked])

  //Handle filter switches.
  const filterRunning = () => {
    if(data === undefined) { return; }
    if(checked.running) {
      setFilteredGanttData(data.map(area => ({
        ...area,
        children: area.children.filter(machine => machine.isRunning === true)
      })));
    }
    else if(checked.onlyStopped && checked.onlyPlanned === false){
      setFilteredGanttData(data.map(area => ({
        ...area,
        children: area.children.filter(machine => machine.isRunning === false)
      })));
    }
    else if(checked.onlyStopped && checked.onlyPlanned){
      setFilteredGanttData(data.map(area => ({
        ...area,
        children: area.children.filter(machine => machine.isRunning === false && machine.isPlannedStop === false)
      })));
    }
    else if(checked.onlyPlanned){
      setFilteredGanttData(data.map(area => ({
        ...area,
        children: area.children.filter(machine => machine.isPlannedStop === false)
      })));
    }
    else{
      setFilteredGanttData(data);
    }
  };

  if (filteredGanttData === undefined || isLoading) {
    return <AxionControls.AxionBackdrop open={true} />
  }

  if(companyQuery == undefined || companyQuery.data === undefined || companyQuery.isLoading) {
    return <AxionControls.AxionBackdrop open={true} />
  }

  if(openActivitiesQuery == undefined || openActivitiesQuery.data === undefined || openActivitiesQuery.isLoading) {
    return <AxionControls.AxionBackdrop open={true} />
  }

  const handleClick = (id) => {
    setShowBackdrop(true);
    fetchMachineStop(id).then((machineStopItem) => {
        fetchMachineData(machineStopItem.machineId).then((machine) => {
        setFilteredStopReasonCodes(stopReasonCodesQuery.data.filter(x => machine.machineStopReasonCodes.some(y => x.id === y.stopReasonCodeId)));
        setMachineStop(machineStopItem);
        setOpenPopup(true);
        setShowBackdrop(false);
      }
      ).catch((error) => {
        console.log(error);
        setShowBackdrop(false);
        pushErrorMessage(`Could not get info about the machine connected to the stop.`);
      })
    }).catch((error) => {
      console.log(error);
      setShowBackdrop(false);
      pushErrorMessage(`Could not get info about the stop.`);
    })
  };

  const handleRefresh = () => {
    refetch();
  }

  const handleSplit = (id) => {
      navigate(`/machinestops/${id}/split`);
  }

  const handleTimeSpan = (ts) => {
    // Update state or perform any other action with childData
    setTimeSpan(ts);
  };

  const handleCheck = (event) => {
    if (event.target.name === "running") {
      if (!checked.running) {
        setCheck({
          ...checked,
          running: true,
          onlyPlanned: false,
          onlyStopped: false,
        });
      }
      if (checked.running) {
        setCheck({
          ...checked,
          running: false,
          onlyPlanned : false
        });
      }
    }
    if (event.target.name === "onlyPlanned") {
      if (!checked.onlyPlanned) {
        if (!checked.onlyStopped) {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: false,
          });
        } else {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: true,
          });
        }
      }
      if (checked.onlyPlanned) {
        setCheck({
          ...checked,
          onlyPlanned: false
        });
      }
    }
    if (event.target.name === "onlyStopped") {
      if (!checked.onlyStopped) {
        if (!checked.onlyPlanned) {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: false,
            onlyStopped: true,
          });
        } else {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: true,
          });
        }
      }
      if (checked.onlyStopped) {
        setCheck({
          ...checked,
          onlyStopped: false,
        });
      }
    };
  };

  return <>
      <Box m="20px" height="94vh" sx={{ backgroundColor: colors.primary[700] }}>
        <Box>
        <Grid container spacing={0} sx={{ paddingTop: "0px" }}>
          <Grid item xs={12} lg={4}>
          <Stack direction="row" width="100%" marginLeft={2} spacing={2}>
            <FormControlLabel
              sx={{
                marginLeft: '5px',
                width: '200px'
              }}
              control={
                <Switch
                  checked={checked.running}
                  //color="secondary"
                  sx={{

                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: "#799c4a !important",
                      '&:hover': {
                        backgroundColor: alpha("#799c4a", theme.palette.action.hoverOpacity),
                      },
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: "#799c4a !important",
                    },
                    // "& .Mui-checked": {
                    //   color: "#799c4a !important",
                    // },
                  }}
                  onChange={handleCheck}
                  name="running"
                />
              }
              label={<FormattedMessage {...messages.onlyRunningFilter} />}
            />
            <FormControlLabel
              sx={{
                width: '300px'
              }}
              control={
                <Switch
                  checked={checked.onlyPlanned}
                  color="secondary"
                  sx={{

                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: "#799c4a !important",
                      '&:hover': {
                        backgroundColor: alpha("#799c4a", theme.palette.action.hoverOpacity),
                      },
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: "#799c4a !important",
                    },
                    // "& .Mui-checked": {
                    //   color: "#799c4a !important",
                    // },
                  }}
                  onChange={handleCheck}
                  name="onlyPlanned"
                />
              }
              label={
                <FormattedMessage {...messages.dontShowPlannedStopsFilter} />
              }
            />
            <FormControlLabel
              sx={{
                width: '220px'
              }}
              control={
                <Switch
                  checked={checked.onlyStopped}
                  //color="secondary"
                  sx={{

                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: "#799c4a !important",
                      '&:hover': {
                        backgroundColor: alpha("#799c4a", theme.palette.action.hoverOpacity),
                      },
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: "#799c4a !important",
                    },
                    // "& .Mui-checked": {
                    //   color: "#799c4a !important",
                    // },
                  }}
                  onChange={handleCheck}
                  name="onlyStopped"
                />
              }
              label={<FormattedMessage {...messages.onlyStoppedFilter} />}
            />
            </Stack>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Stack direction="row" width="100%" marginLeft={2} spacing={2}>
                <DateFilter onDataReceived={handleTimeSpan} startDate={startDate} endDate={endDate} hideManual={true} showWeek={true} />
              </Stack>
            </Grid>
        </Grid>
        </Box>
        <Gantt data={filteredGanttData} handleClick={handleClick} startDate={startDate} endDate={endDate} company={companyQuery?.data} openActivities={openActivitiesQuery?.data} />
      </Box>
      <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          maxWidht="sm"
          title={<FormattedMessage {...messages.editStop} />}>
          <MachineStopEditDialog 
              stopReasonCodes={filteredStopReasonCodes}
              refresh={handleRefresh}
              machineStop={machineStop}
              handleSplit={handleSplit}
              setOpenPopup={setOpenPopup}
              setShowBackdrop={setShowBackdrop} />
      </Popup>
      <AxionControls.AxionBackdrop open={showBackdrop} />
    </>
}