import React, { useState, useEffect } from "react";
//COMPONENTS
import { CustomFooterTotalComponent } from "../home/components/customFooter";
import GridDatePicker from "./components/GridDatePicker";
import GridDateTimePicker from "./components/GridDateTimePicker";
//REACT QUERY
import {
  useQuery,
  useMutation,
  useQueryClient,
  useQueries,
} from "@tanstack/react-query";
//LANGUAGE HANDLING
import messages from "./messages";
import { FormattedMessage, useIntl } from "react-intl";
//HOOKSF
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  fetchOeeDashboardDataTimeSpan,
  updateMachinePriority,
  fetchOeeGridDateIntervall,
  fetchOeeDashboardDataTimeSpanDev,
  fetchWorkOrderComment,
  updateWorkOrderComment,
} from "../home/hooks/fetchers";
import { fetchCompany } from "../../fetchers/company";
import { fetchMachines } from "../../fetchers/machine";
import { getOpenActivities } from "../activities/hooks/fetchers";
//STYLE AND THEMING
import { tokens } from "../../assets/theme";
//MUI COMPONENTS
import {
  Box,
  Typography,
  useTheme,
  Stack,
  CircularProgress,
  Grid,
  Link,
  useMediaQuery,
  Tooltip,
  Avatar
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { alpha, styled } from '@mui/material/styles';
import {
  DataGridPro,
  GridLogicOperator,
  useGridApiRef,
  gridFilterModelSelector,
  GridToolbar,
  gridExpandedSortedRowIdsSelector
} from "@mui/x-data-grid-pro";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Badge from '@mui/material/Badge';
//OTHER 3party Components
import dayjs from "dayjs";
//ICONS
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import AxionControls from "../../components/controls/AxionControls";
import Popup from "../../components/Popup";
import WorkOrderCommentForm from "./components/WorkOrderCommentForm";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import OeeSummarize from "./components/oeeSummarize";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isOnMobile = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const { formatMessage } = useIntl();

  const [columnStorage, setColumnStorage] = useLocalStorage(
    "oeeGridColumnStorage",
    !isOnMobile ? {
      plannedStop: false,
      prioritySortOrder: false,
      plannedQty: false,
      reportedQty: false,
      remainingQty: false,
      uptime: false,
      downTime: false,
      scrappedQty: false,
      numberOfCyclesWithCavity: false,
    } :
    {
      areaName: false,
      activeProductionOrder: false,
      activeItem: false,
      tool: false,
      openActivities: false,
      idealCycleTimeActive: false,
      avgIdealCycleTime: false,
      avgCycleTime: false,
      lastCycleTime: false,
      plannedStop: false,
      prioritySortOrder: false,
      plannedQty: false,
      reportedQty: false,
      remainingQty: false,
      uptime: false,
      downTime: false,
      scrappedQty: false,
      numberOfCyclesWithCavity: false,
    }
  );

  const [filtersStorage, setFiltersStorage] = useLocalStorage("filterStorage", {
    items: [],
    linkOperator: GridLogicOperator.And,
  });

  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [workOrder,setWorkOrder] = useState(null);
  
  const openInPopupInsert = (item) => {
    setWorkOrder(item);
    setRecordForEdit(null);
    setOpenPopup(true);
  };

  const openInPopup = (item) => {
    setRecordForEdit(
      workOrderCommentQuery.data.filter((list) => list.id === item)[0]
    );
    setOpenPopup(true);
  };

  const getComment = (id) => {
    return workOrderCommentQuery.data.filter((list) => list.id === id)[0].comment;
  };

  const [sortModelStorage, setSortModelStorage] = useLocalStorage("sortModel", [
    { field: "areaName", sort: "asc" },
    { field: "prioritySortOrder", sort: "asc" },
  ]);

  //Functions to change Start and endDate state from datepickers
  function isDateValid(dateStr) {
    return !isNaN(new Date(dateStr));
  }
  function startDateChangeHandler(date) {
    if (isDateValid(date)){
      setStartDate(date);
    }
  }
  function endDateChangeHandler(date) {
    if (isDateValid(date)){
      setEndDate(date);
    }
  }

  function isPerfomanceActiveForMachine(id) {
    if(machinesQuery.data?.filter((machines) => machines.id === id)[0].meassurePerformance === undefined) return true;
    return machinesQuery.data?.filter((machines) => machines.id === id)[0].meassurePerformance
  }

  function isQualityActiveForMachine(id) {
    if(machinesQuery.data?.filter((machines) => machines.id === id)[0].meassureQuality === undefined) return true;
    return machinesQuery.data?.filter((machines) => machines.id === id)[0].meassureQuality
  }

  function getAvalabilityThresholds(id) {
    let percentWarning = 78;
    let percentBad = 60;
    const machine = machinesQuery.data?.filter((machines) => machines.id === id)[0];

    if(machine?.availabilityPercentBad !== undefined && machine?.availabilityPercentBad !== null) {
        percentBad = machine.availabilityPercentBad;
    }
    else if(companyQuery.data?.availabilityPercentBad !== undefined && companyQuery.data?.availabilityPercentBad !== null) {
        percentBad = companyQuery.data?.availabilityPercentBad;
    }
    else if(machine?.oeePercentBad !== undefined && machine?.oeePercentBad !== null) {
      percentBad = machine.oeePercentBad;
    }
    else if(companyQuery.data?.oeePercentBad !== undefined && companyQuery.data?.oeePercentBad !== null) {
        percentBad = companyQuery.data?.oeePercentBad;
    }

    if(machine?.availabilityPercentWarning !== undefined && machine?.availabilityPercentWarning !== null) {
        percentWarning = machine.availabilityPercentWarning;
    }
    else if(companyQuery.data?.availabilityPercentBad !== undefined && companyQuery.data?.availabilityPercentBad !== null) {
        percentWarning = companyQuery.data?.availabilityPercentWarning;
    }
    else if(machine?.oeePercentBad !== undefined && machine?.oeePercentWarning !== null) {
      percentWarning = machine.oeePercentWarning;
    }
    else if(companyQuery.data?.oeePercentBad !== undefined && companyQuery.data?.oeePercentBad !== null) {
        percentWarning = companyQuery.data?.oeePercentWarning;
    }
    return { percentWarning: percentWarning, percentBad: percentBad}
  }

  function getPerformanceThresholds(id) {
    let percentWarning = 78;
    let percentBad = 60;
    const machine = machinesQuery.data?.filter((machines) => machines.id === id)[0];

    if(machine?.performancePercentBad !== undefined && machine?.performancePercentBad !== null) {
        percentBad = machine.performancePercentBad;
    }
    else if(companyQuery.data?.performancePercentBad !== undefined && companyQuery.data?.performancePercentBad !== null) {
        percentBad = companyQuery.data?.performancePercentBad;
    }
    else if(machine?.oeePercentBad !== undefined && machine?.oeePercentBad !== null) {
      percentBad = machine.oeePercentBad;
    }
    else if(companyQuery.data?.oeePercentBad !== undefined && companyQuery.data?.oeePercentBad !== null) {
        percentBad = companyQuery.data?.oeePercentBad;
    }

    if(machine?.performancePercentWarning !== undefined && machine?.performancePercentWarning !== null) {
        percentWarning = machine.performancePercentWarning;
    }
    else if(companyQuery.data?.performancePercentBad !== undefined && companyQuery.data?.performancePercentBad !== null) {
        percentWarning = companyQuery.data?.performancePercentWarning;
    }
    else if(machine?.oeePercentBad !== undefined && machine?.oeePercentWarning !== null) {
      percentWarning = machine.oeePercentWarning;
    }
    else if(companyQuery.data?.oeePercentBad !== undefined && companyQuery.data?.oeePercentBad !== null) {
        percentWarning = companyQuery.data?.oeePercentWarning;
    }
    return { percentWarning: percentWarning, percentBad: percentBad}
  }

  function getQualityThresholds(id) {
    let percentWarning = 78;
    let percentBad = 60;
    const machine = machinesQuery.data?.filter((machines) => machines.id === id)[0];

    if(machine?.qualityPercentBad !== undefined && machine?.qualityPercentBad !== null) {
        percentBad = machine.qualityPercentBad;
    }
    else if(companyQuery.data?.qualityPercentBad !== undefined && companyQuery.data?.qualityPercentBad !== null) {
        percentBad = companyQuery.data?.qualityPercentBad;
    }
    else if(machine?.oeePercentBad !== undefined && machine?.oeePercentBad !== null) {
      percentBad = machine.oeePercentBad;
    }
    else if(companyQuery.data?.oeePercentBad !== undefined && companyQuery.data?.oeePercentBad !== null) {
        percentBad = companyQuery.data?.oeePercentBad;
    }

    if(machine?.qualityPercentWarning !== undefined && machine?.qualityPercentWarning !== null) {
        percentWarning = machine.performancePercentWarning;
    }
    else if(companyQuery.data?.qualityPercentWarning !== undefined && companyQuery.data?.qualityPercentWarning !== null) {
        percentWarning = companyQuery.data?.qualityPercentWarning;
    }
    else if(machine?.oeePercentBad !== undefined && machine?.oeePercentWarning !== null) {
      percentWarning = machine.oeePercentWarning;
    }
    else if(companyQuery.data?.oeePercentBad !== undefined && companyQuery.data?.oeePercentBad !== null) {
        percentWarning = companyQuery.data?.oeePercentWarning;
    }
    return { percentWarning: percentWarning, percentBad: percentBad}
  }

  function getOeeThresholds(id) {
    let percentWarning = 78;
    let percentBad = 60;
    const machine = machinesQuery.data?.filter((machines) => machines.id === id)[0];

    if(machine?.oeePercentBad !== undefined && machine?.oeePercentBad !== null) {
        percentBad = machine.oeePercentBad;
    }
    else if(companyQuery.data?.oeePercentBad !== undefined && companyQuery.data?.oeePercentBad !== null) {
        percentBad = companyQuery.data?.oeePercentBad;
    }

    if(machine?.oeePercentBad !== undefined && machine?.oeePercentWarning !== null) {
        percentWarning = machine.oeePercentWarning;
    }
    else if(companyQuery.data?.oeePercentBad !== undefined && companyQuery.data?.oeePercentBad !== null) {
        percentWarning = companyQuery.data?.oeePercentWarning;
    }
    return { percentWarning: percentWarning, percentBad: percentBad}
  }

  function clearAllFilters() {
    setCheck({
      running: false,
      onlyPlanned: false,
      onlyStopped: false,
    });
    setFiltersStorage({ items: [] });
  }

  //Total number of machines in grid (affected by filters)
  const [total, setTotal] = useState(0);
  const [totalA, setTotalA] = useState(0);
  const [totalT, setTotalT] = useState(0);
  const [totalK, setTotalK] = useState(0);
  const [totalColor, setTotalColor] = useState(colors.dashbordGreen[600]);
  const [totalAColor, setTotalAColor] = useState(colors.dashbordGreen[600]);
  const [totalTColor, setTotalTColor] = useState(colors.dashbordGreen[600]);
  const [totalKColor, setTotalKColor] = useState(colors.dashbordGreen[600]);

  //Company key
  const [companyKey, setCompanyKey] = useLocalStorage("companyKey", 1);
  //State for datepicker component
  const [startDate, setStartDate] = useLocalStorage("startDate", dayjs());
  const [endDate, setEndDate] = useLocalStorage("endDate", dayjs());
 
  //Total number och machines running (affected by filters)
  const [machinesRunning, setMachinesRunning] = useState(0);
  //Total number och machines stopped in grid (affected by filters)
  const [machinesStopped, setMachinesStopped] = useState(0);
  //Total number och machines in planned stop (affected by filters)
  const [machinesPlannedStop, setMachinesPlannedStop] = useState(0);
  //timespan parameter to API Fetch default currentshift (24h, 72h available too)
  const [timeSpan, setTimeSpan] = useLocalStorage("timespan", "currentshift");
  //React Query client
  const queryClient = useQueryClient();
  const [selectedValue, setSelectedValue] = React.useState(timeSpan);
  //State to handle datepicker component visibility
  const [showDatepicker, setShowDatePicker] = useState(selectedValue === 'manual');

  //Change handle for radio buttons when selecting new timespan for api fetch.
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    //Check if manual timespan selected, if selected then show datepickers.
    setTimeSpan(event.target.value);
    if (event.target.value !== "manual") {
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
  };
  //Message snackbar on updated priority
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  //Save Machine Priority with mutation
  const useFakeMutation = () => {
    return React.useCallback(
      (item) =>
        new Promise((resolve, reject) =>
          setTimeout(() => {
            if (item.priorityName?.trim() === "") {
              reject(
                new Error("Error while saving user: name can't be empty.")
              );
            } else {
              updateMachinePriorityMutation.mutate({
                ...item,
                completed: !item.completed,
              });
              resolve({ ...item, priorityName: item.priorityName?.toString() });
            }
          }, 200)
        ),
      []
    );
  };

  //Icon handing for Machine Priority column
  const machinePriorityOptions = [
    {
      value: "1",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>1</Typography>
        </IconButton>
      ),
    },
    {
      value: "2",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>2</Typography>
        </IconButton>
      ),
    },
    {
      value: "3",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>3</Typography>
        </IconButton>
      ),
    },
    {
      value: "4",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>4</Typography>
        </IconButton>
      ),
    },
    {
      value: "5",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>5</Typography>
        </IconButton>
      ),
    },
    {
      value: "6",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>6</Typography>
        </IconButton>
      ),
    },
    {
      value: "7",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>7</Typography>
        </IconButton>
      ),
    },
    {
      value: "8",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>8</Typography>
        </IconButton>
      ),
    },
    {
      value: "9",
      label: "1" && (
        <IconButton
          sx={{ backgroundColor: colors.gray[500], width: 45, height: 45 }}
        >
          <Typography sx={{ fontSize: 30 }}>9</Typography>
        </IconButton>
      ),
    },
    {
      value: "Pil AUpp",
      label: "1" && (
        <IconButton sx={{ backgroundColor: colors.redAccent[500] }}>
          {" "}
          <ArrowUpwardIcon sx={{ fontSize: 28 }} />{" "}
        </IconButton>
      ),
    },
    {
      value: "Pil BHöger",
      label: "100" && (
        <IconButton sx={{ backgroundColor: colors.blueAccent[600] }}>
          <ArrowForwardIcon sx={{ fontSize: 28 }} />{" "}
        </IconButton>
      ),
    },
    {
      value: "Pil CNed",
      label: "99" && (
        <IconButton sx={{ backgroundColor: colors.dashbordGreen[500] }}>
          <ArrowDownwardIcon sx={{ fontSize: 28 }} />{" "}
        </IconButton>
      ),
    },
  ];

  //MUI Grid API
  const gridApi = useGridApiRef();
  //Mutations
  const mutateRow = useFakeMutation();

  const saveState = () => {
    const state = gridApi.current.exportState();
    setColumnStorage(state.columns.columnVisibilityModel);

  };

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({
        children: "Priority successfully saved",
        severity: "success",
      });
      return response;
    },
    [mutateRow]
  );

//Workorder comments
const addOrEdit = (comment, resetForm) => {
  updateworkOrderCommentQuery(comment);
  resetForm();
  setRecordForEdit(null);
  setOpenPopup(false);
};
const updateworkOrderCommentQuery = (workOrderComment) => {
  updateWorkOrderCommentMutation.mutate({
    id: workOrderComment.id,
    workOrderNumber: workOrderComment.workOrderNumber,
    comment: workOrderComment.comment,
    companyKey: workOrderComment.companyKey,
    created: null,
    createdBy: workOrderComment.createdBy,
  });
};
//MUTATIONS
const updateWorkOrderCommentMutation = useMutation(updateWorkOrderComment, {
  onSuccess: () => {
    queryClient.invalidateQueries({queryKey: ["oeedata"]}, {queryKey: ["workOrderComment"]});
    workOrderCommentQuery.refetch();
  },
});

  //Callbacks
  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  //React Querys
  const [timespanQuery, dateQuery, workOrderCommentQuery, machinesQuery, companyQuery, openActivitiesQuery] = useQueries({
    queries: [
      {
        queryKey: ["oeedata", timeSpan],
        queryFn: () => fetchOeeDashboardDataTimeSpanDev(timeSpan, companyKey),
        staleTime: 60000,
        refetchInterval: 60000,
        enabled: !showDatepicker,
      },
      {
        queryKey: ["oeedataDatePicker"],
        queryFn: () =>
          fetchOeeGridDateIntervall(startDate, endDate, companyKey),
        staleTime: 60000,
        refetchInterval: 60000,
        enabled: showDatepicker,
      },
      {
        queryKey: ["workOrderComment"],
        queryFn: () => fetchWorkOrderComment(companyKey),
        staleTime: 60000,
        refetchInterval: 60000,
        enabled: true,
      },
      {
        queryKey: ["machines", companyKey],
        queryFn: () => fetchMachines(companyKey),
        staleTime: 60000,
        refetchInterval: 60000,
        enabled: true,
      },
      {
        queryKey: ["company", companyKey],
        queryFn: () => fetchCompany(companyKey),
        staleTime: 60000,
        refetchInterval: 60000,
        enabled: true,
      },
      {
        queryKey: ["openActivities", companyKey],
        queryFn: () => getOpenActivities(companyKey),
        staleTime: 60000,
        refetchInterval: 60000,
        enabled: true,
      }
    ],
  });


 //Track datechanges to call api again
 useEffect(() => { 
  if (selectedValue === "week") {
    setSelectedValue ("currentshift");
    setTimeSpan("currentshift");
    setShowDatePicker(false);
  }
}, [selectedValue, timeSpan]);

  //Track datechanges to call api again
  useEffect(() => {
    if (showDatepicker) {
      dateQuery.remove();
      dateQuery.refetch();
    }
  }, [endDate]);

  useEffect(() => {
    if (showDatepicker) {
      dateQuery.remove();
      dateQuery.refetch();
    }
  }, [startDate]);

  useEffect(() => {
    if(total < companyQuery.data?.oeePercentBad) {
      setTotalColor(colors.redAccent[600])
    }
    else if(total < companyQuery.data?.oeePercentWarning) {
      setTotalColor(colors.yellow[600])
    }
    else {
      setTotalColor(colors.dashbordGreen[600])
    }
    
    const availabilityPercentBad = companyQuery.data?.availabilityPercentBad ?? companyQuery.data?.oeePercentBad;
    const availabilityPercentWarning = companyQuery.data?.availabilityPercentWarning ?? companyQuery.data?.oeePercentWarning;
    if(totalT < availabilityPercentBad) {
      setTotalTColor(colors.redAccent[600])
    }
    else if(totalT < availabilityPercentWarning) {
      setTotalTColor(colors.yellow[600])
    }
    else {
      setTotalTColor(colors.dashbordGreen[600])
    }

    const performancePercentBad = companyQuery.data?.performancePercentBad ?? companyQuery.data?.oeePercentBad;
    const performancePercentWarning = companyQuery.data?.performancePercentWarning ?? companyQuery.data?.oeePercentWarning;
    if(totalA < performancePercentBad) {
      setTotalAColor(colors.redAccent[600])
    }
    else if(totalA < performancePercentWarning) {
      setTotalAColor(colors.yellow[600])
    }
    else {
      setTotalAColor(colors.dashbordGreen[600])
    }

    const qualityPercentBad = companyQuery.data?.qualityPercentBad ?? companyQuery.data?.oeePercentBad;
    const qualityPercentWarning = companyQuery.data?.qualityPercentWarning ?? companyQuery.data?.oeePercentWarning;
    if(totalK < qualityPercentBad) {
      setTotalKColor(colors.redAccent[600])
    }
    else if(totalK < qualityPercentWarning) {
      setTotalKColor(colors.yellow[600])
    }
    else {
      setTotalKColor(colors.dashbordGreen[600])
    }

  }, [total, totalA, totalK, totalT, companyQuery.data]);

  //Update machine priority and invalidate OEEdata query to fetch new records.
  const updateMachinePriorityMutation = useMutation(updateMachinePriority, {
    onSuccess: () => {
      queryClient.invalidateQueries("oeedata");
    },
  });

  //Handle filter switches.
  const filterRunning = () => {
    const thisFilter = {
      field: "machineStatus",
      operator: "equals",
      value: "Running",
      id: 2023,
    };
    if (filtersStorage.items.length !== 0) {
      var runningFilter = {
        ...filtersStorage,
        items: [
          ...filtersStorage.items.filter(
            (items) => items.id !== 2024 && items.id !== 2025
          ),
          thisFilter,
        ],
      };
    } else {
      runningFilter = {
        ...filtersStorage,
        items: [thisFilter],
      };
    }
    setFiltersStorage(runningFilter);

    if (gridApi.current) {
      gridApi.current.setFilterModel(runningFilter);
    }
  };

  const unfilterRunning = () => {
    const state = gridApi.current.state;
    const filterModel = gridFilterModelSelector(state);
    if (filterModel.items.length !== 0) {
      const itemToDelete = filterModel.items.filter((list) => list.id === 2023);
      const newState = filterModel.items.filter((list) => list.id !== 2023);
      setFiltersStorage(newState);

      gridApi.current.deleteFilterItem(itemToDelete[0]);
    }
  };

  const filterStopped = () => {
    const thisFilter = {
      field: "machineStatus",
      operator: "equals",
      value: "Stopped",
      id: 2024,
    };

    if (filtersStorage.items.length !== 0) {
      var runningFilter = {
        ...filtersStorage,
        items: [
          ...filtersStorage.items.filter((items) => items.id !== 2023),
          thisFilter,
        ],
      };
    } else {
      runningFilter = {
        ...filtersStorage,
        items: [thisFilter],
      };
    }
    setFiltersStorage(runningFilter);

    if (gridApi.current) {
      gridApi.current.setFilterModel(runningFilter);
    }
  };

  const unfilterStopped = () => {
    const state = gridApi.current.state;
    const filterModel = gridFilterModelSelector(state);
    if (filterModel.items.length !== 0) {
      const itemToDelete = filterModel.items.filter((list) => list.id === 2024);
      const newState = filterModel.items.filter((list) => list.id !== 2024);
      setFiltersStorage(newState);

      gridApi.current.deleteFilterItem(itemToDelete[0]);
    }
  };

  const filterPlannedStops = () => {
    const thisFilter = {
      field: "plannedStop",
      operator: "equals",
      value: "0",
      id: 2025,
    };

    if (filtersStorage.items.length !== 0) {
      var runningFilter = {
        ...filtersStorage,
        items: [
          ...filtersStorage.items.filter((items) => items.id !== 2023),
          thisFilter,
        ],
      };
    } else {
      runningFilter = {
        ...filtersStorage,
        items: [thisFilter],
      };
    }
    setFiltersStorage(runningFilter);

    if (gridApi.current) {
      gridApi.current.setFilterModel(runningFilter);
    }
  };

  const unfilterPlannedStops = (newValue) => {
    const state = gridApi.current.state;
    const filterModel = gridFilterModelSelector(state);

    if (filterModel.items.length !== 0) {
      const itemToDelete = filterModel.items.filter((list) => list.id === 2025);
      const newState = filterModel.items.filter((list) => list.id !== 2025);
      setFiltersStorage(newState);

      gridApi.current.deleteFilterItem(itemToDelete[0]);
    }
  };

  //Handle switch button states.
  const [checked, setCheck] = useLocalStorage("checked", {
    running: false,
    onlyPlanned: false,
    onlyStopped: false,
  });

  const handleCheck = (event) => {
    if (event.target.name === "running") {
      if (!checked.running) {
        setCheck({
          ...checked,
          running: true,
          onlyPlanned: false,
          onlyStopped: false,
        });
        filterRunning();
      }
      if (checked.running) {
        setCheck({
          ...checked,
          running: false,
        });
        unfilterRunning();
      }
    }
    if (event.target.name === "onlyPlanned") {
      if (!checked.onlyPlanned) {
        if (!checked.onlyStopped) {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: false,
          });
        } else {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: true,
          });
        }

        filterPlannedStops();
      }
      if (checked.onlyPlanned) {
        setCheck({
          ...checked,
          onlyPlanned: false,
        });
        unfilterPlannedStops();
      }
    }
    if (event.target.name === "onlyStopped") {
      if (!checked.onlyStopped) {
        if (!checked.onlyPlanned) {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: false,
            onlyStopped: true,
          });
        } else {
          setCheck({
            ...checked,
            running: false,
            onlyPlanned: true,
            onlyStopped: true,
          });
        }

        filterStopped();
      }
      if (checked.onlyStopped) {
        setCheck({
          ...checked,
          onlyStopped: false,
        });
        unfilterStopped();
      }
    }
  };

  //Define columns 
  let columns = [
    {
      field: "areaName",
      headerName: "Area",
      width: 120,
    },
    {
      field: "machineName",
      headerName: <FormattedMessage {...messages.machineNameColumn} />,
      width: 80,
      headerAlign: "left",
      renderCell: ({ id, value, field }) => {
        return <Link href={`/machines/${id}`} sx={{color: '#fff', fontSize: 16 }}>{value}</Link>
      },
    },
    {
      field: "priorityName",
      headerName: <FormattedMessage {...messages.priorityNameColumn} />,
      type: "singleSelect",
      valueOptions: machinePriorityOptions,
      renderCell: ({ id, value, field }) => {
        const option = machinePriorityOptions.find(
          ({ value: optionValue }) => optionValue === value
        );

        return option?.label;
      },

      width: 80,
      editable: true,
      headerAlign: "left",
    },
    {
      field: "machineStatus",
      headerName: <FormattedMessage {...messages.machineStatusColumn} />,
      type: "string",
      width: 100,
      renderCell: ({ row: { machineStatus } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              machineStatus === "Running"
                ? colors.dashbordGreen[600]
                : machineStatus === "Offline"
                ? colors.yellow[500]
                : colors.redAccent[500]
            }
            borderRadius="4px"
          >
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {machineStatus}
            </Typography>
          </Box>
        );
      },
      headerAlign: "center",
    },
    {
      field: "currentStop",
      headerName: <FormattedMessage {...messages.currentStopColumn} />,
      width: 95,
      //flex: 1,
    },
    {
      field: "currentStopTime",
      headerName: <FormattedMessage {...messages.currentStopTimeColumn} />,
      width: 80,
    },
    {
      field: "plannedStop",
      headerName: <FormattedMessage {...messages.plannedStopColumn} />,
      flex: 1,
    },
    {
      field: "activeProductionOrder",
      headerName: (
        <FormattedMessage {...messages.activeProductionOrderColumn} />
      ),
      width: 75,
    },
    {
      field: "activeItem",
      headerName: <FormattedMessage {...messages.activeItemColumn} />,
      width: 100,
    },
    {
      field: "tool",
      headerName: <FormattedMessage {...messages.toolColumn} />,
      width: 55,
    },
    {
      field: "plannedQty",
      type: "number",
      headerName: <FormattedMessage {...messages.plannedQtyColumn} />,
      flex: 1,
      cellClassName: "font-tabular-nums",
    },
    {
      field: "reportedQty",
      type: "number",
      headerName: <FormattedMessage {...messages.reportedQtyColumn} />,
      flex: 1,
    },
    {
      field: "remainingQty",
      type: "number",
      headerName: <FormattedMessage {...messages.remainingQtyColumn} />,
      flex: 1,
    },
    {
      field: "openActivities",
      type: "number",
      headerAlign: "left",
      headerName: <FormattedMessage {...messages.openActivitiesColumn} />,
      renderCell: ({ row: { openActivities, id } }) => {
        
        return (
       <>
          {openActivities > 0 ? 
            <>
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            borderRadius="4px"
          >
              <Link href={`/machines/${id}?tab=1`} sx={{ textDecoration: 'none' }}>
                <Tooltip title={<Typography style={{ whiteSpace: 'pre-line' }} fontSize="14px">{getActivitesForMachine(id)}</Typography>}>
                  <Avatar sx={{ backgroundColor: colors.yellow[600] , color:"#000", height:"35px", width:"35px", fontSize: 16 }}>{openActivities}</Avatar>
                </Tooltip>
              </Link>
          </Box>
          </>
          :
          <Box>
          <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
          
        </Typography>
          </Box>
          }
          </>
        );
      },
      flex: 1,
    },

    {
      field: "t",
      headerName: <FormattedMessage {...messages.tColumn} />,
      type: "number",
      renderCell: ({ row: { t, id } }) => {
        const {percentBad, percentWarning} = getAvalabilityThresholds(id);
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              t > percentWarning
                ? colors.dashbordGreen[600]
                : t > percentBad
                ? colors.yellow[600]
                : colors.redAccent[600]
            }
            borderRadius="4px"
          >
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {Number.parseFloat(t).toFixed(0)} %
            </Typography>
          </Box>
        );
      },
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "a",
      headerName: <FormattedMessage {...messages.aColumn} />,
      type: "number",
      renderCell: ({ row: { a, id } }) => {
        if(!isPerfomanceActiveForMachine(id)) {
          return (
            <Box
              width="100%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={
                colors.primary[300]
              }
              borderRadius="4px"
            >
              <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
                -
              </Typography>
            </Box>
          );
        }
        const {percentBad, percentWarning} = getPerformanceThresholds(id);
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              a > percentWarning
                ? colors.dashbordGreen[600]
                : a > percentBad
                ? colors.yellow[600]
                : colors.redAccent[600]
            }
            borderRadius="4px"
          >
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {Number.parseFloat(a).toFixed(0)} %
            </Typography>
          </Box>
        );
      },
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "k",
      headerName: <FormattedMessage {...messages.kColumn} />,
      type: "number",
      width: 50,
      renderCell: ({ row: { k, id } }) => {
        if(!isQualityActiveForMachine(id)) {
          return (
            <Box
              width="100%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={
                colors.primary[300]
              }
              borderRadius="4px"
            >
              <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
                -
              </Typography>
            </Box>
          );
        }
        const {percentBad, percentWarning} = getQualityThresholds(id);
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              k > percentWarning
                ? colors.dashbordGreen[600]
                : k > percentBad
                ? colors.yellow[600]
                : colors.redAccent[600]
            }
            borderRadius="4px"
          >
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {Number.parseFloat(k).toFixed(0)} %
            </Typography>
          </Box>
        );
      },
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "tak",
      headerName: <FormattedMessage {...messages.takColumn} />,
      type: "number",
      // valueGetter: (params) => {
      //   if (params.row.tak > 100) {
      //     params.row.tak = 100.00;
      //   }
      // },
      valueFormatter: (params) => {
        if (params.value > 100) {
          return Number.parseFloat(100.0).toFixed(0);
        }
        return Number.parseFloat(params.value).toFixed(0);
      },
      renderCell: ({ row: { t, a, k, id } }) => {
        const {percentBad, percentWarning} = getOeeThresholds(id);
        let aPercent = a;
        let kPercent = k;
        if(!isPerfomanceActiveForMachine(id)) {
          aPercent = 100;
        }
        else if (a > 100) {
          aPercent = 100;
        }

        if(!isQualityActiveForMachine(id)) {
          kPercent = 100;
        }

        const tak = t * aPercent * kPercent / 10000;
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              tak > percentWarning
                ? colors.dashbordGreen[600]
                : tak > percentBad
                ? colors.yellow[600]
                : colors.redAccent[600]
            }
            borderRadius="4px"
          >
            <Typography color={colors.gray[100]} sx={{ ml: "5px" }}>
              {Number.parseFloat(tak).toFixed(0)} %
            </Typography>
          </Box>
        );
      },

      headerAlign: "center",
      flex: 1,
    },
    {
      field: "idealCycleTimeActive",
      headerName: <FormattedMessage {...messages.idealCycleTimeActiveColumn} />,
      width: 75,
    },
    {
      field: "lastCycleTime",
      headerName: <FormattedMessage {...messages.lastCycleTimeColumn} />,
      width: 75,
      renderCell: ({ row: { idealCycleTimeActive, lastCycleTime } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={ () => {
              if (lastCycleTime === 0 || lastCycleTime === "") {
                return colors.primary[300];
              }
          
              var yellowExpandPercent = 0.05;
              var redExpandPercent = 0.1;
              var result = colors.dashbordGreen[600];
              if (lastCycleTime > (idealCycleTimeActive + idealCycleTimeActive * redExpandPercent)) {
                  result = colors.redAccent[600];
              }
              else if (lastCycleTime > (idealCycleTimeActive + idealCycleTimeActive * yellowExpandPercent)) {
                  result = colors.yellow[600];
              }
              return result;
            }}
            borderRadius="4px"
          >
            <Typography color={colors.gray[100]} sx={{ ml: "4px" }}>
              {Number.parseFloat(lastCycleTime).toFixed(2)}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: "avgCycleTime",
      renderCell: ({ row: { avgCycleTime } }) => {
        return (
          <Typography color={colors.gray[100]} sx={{ ml: "4px" }}>
            {Number.parseFloat(avgCycleTime).toFixed(2)}
          </Typography>
        );
      },
      headerName: <FormattedMessage {...messages.avgCycleTimeColumn} />,
      width: 75,
    },
    {
      field: "avgIdealCycleTime",
      headerName: <FormattedMessage {...messages.avgIdealCycleTimeColumn} />,
      width: 75,
    },
    {
      field: "prioritySortOrder",
      headerName: "PrioritySortOrder",
    },
    {
      field: "uptime",
      headerName: <FormattedMessage {...messages.uptimeColumn} />,
    },
    {
      field: "downTime",
      headerName: <FormattedMessage {...messages.downTimeColumn} />,
    },
    {
      field: "scrappedQty",
      headerName: <FormattedMessage {...messages.scrappedQtyColumn} />,
    },
    {
      field: "numberOfCyclesWithCavity",
      headerName: <FormattedMessage {...messages.numberOfCyclesWithCavityColumn} />,
    },
    {
      field: "remainingTime",
      headerName: <FormattedMessage {...messages.remainingTimeColumn} />,
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        return `${params.value.toFixed(2)} ${formatMessage(messages.hours)}`;
      },
    },
    {
      field: "commentId",
    
      headerName: <FormattedMessage {...messages.commentColumn} />,
      renderCell: ({ row: { commentId, activeProductionOrder } }) => {
        if (commentId !== 0) {
          return (
            <Tooltip title={<Typography style={{ whiteSpace: 'pre-line' }} fontSize="14px">{getComment(commentId)}</Typography>}>
              <IconButton
                arial-label="ShowComment"
                sx={{
                  "&:hover": {
                    backgroundColor: colors.yellow[700],
                    color: colors.gray[700],
                  },
                  backgroundColor: colors.yellow[600],
                  color: colors.gray[700],
                }}
                onClick={() => {
                  openInPopup(commentId);
                }}
              >
                <ChatOutlinedIcon />
              </IconButton>
            </Tooltip>  
          );
        } else {
          return (
            <IconButton
              arial-label="ShowComment"
              sx={{
                "&:hover": {
                  backgroundColor: colors.primary[700],
                },
                backgroundColor: colors.primary[500],
              }}
              onClick={() => {
                openInPopupInsert(activeProductionOrder);
              }}
            >
            
              <AddOutlinedIcon />
            </IconButton>
          );
        }
      },
      flex: 1,
    },
  ];

  const columnOrderLocalStorageName = 'machines-grid-columnOrder';
  const columnWidthsLocalStorageName = 'machines-grid-columnWidths';

  function getActivitesForMachine(id) {
    const activities = openActivitiesQuery?.data.filter((list) => list.machineId === id).map((list) => `${list.title} ${calculateRemaingTime(list.maxClosedDate)}` ).join("\n");
    return activities;
  }

  function calculateRemaingTime(maxClosedDate) {
    let timeRemaining = (new Date(maxClosedDate) - Date.now()) / 1000;
    
    // Convert seconds to hours, minutes, and seconds
    const hours = Math.floor(timeRemaining / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = Math.floor(timeRemaining % 60);

    return `${hours} h ${minutes} m ${seconds} s`;
  }
  
  // Load persisted column order, widths, and sort order on mount
  function initColumnsFromLocalStorage(columns) {
    let columnsToFix = columns;
    // Load column order
    const savedColumnOrder = localStorage.getItem(columnOrderLocalStorageName);
    if (savedColumnOrder) {
      const orderArray = JSON.parse(savedColumnOrder);
      columnsToFix = reorderColumns(orderArray, columnsToFix);
    }

    // Load column widths
    const savedWidths = localStorage.getItem(columnWidthsLocalStorageName);
    if (savedWidths) {
        const widthMap = JSON.parse(savedWidths);
        columnsToFix = applyColumnWidths(widthMap, columnsToFix);
      }
    return columnsToFix;
  };


  // Reorder columns based on saved order
  const reorderColumns = (orderArray, columnsToFix) => {
    const reorderedColumns = orderArray.map((field) =>
        columnsToFix.find((col) => col.field === field)
    );
    reorderedColumns.push(...columnsToFix.filter((col) => !reorderedColumns.includes(col)));
    return reorderedColumns;
  };

  // Apply saved column widths
  const applyColumnWidths = (widthMap, columnsToFix) => {
    const updatedColumns = columnsToFix.map((col) => ({
      ...col,
      width: widthMap[col.field] || col.width, 
      flex: widthMap[col.field] !== undefined ? undefined : col.flex, 
    }));
    return updatedColumns;
  };

  // Handle column order change
  const handleColumnOrderChange = () => {
    if (!gridApi.current) {
      return;
    }
    const orderedColumns = gridApi.current.getVisibleColumns();
    const newColumnOrder = orderedColumns.map((col) => col.field);
    localStorage.setItem(columnOrderLocalStorageName, JSON.stringify(newColumnOrder));
  };

  // Handle column resize
  const handleColumnResize = (params) => {
    const { colDef, width } = params;
    const newWidths = JSON.parse(localStorage.getItem(columnWidthsLocalStorageName)) || {};
    newWidths[colDef.field] = width;
    localStorage.setItem(columnWidthsLocalStorageName, JSON.stringify(newWidths));
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  let content;
  let radiobuttons;

  radiobuttons = (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="currentShift"
          control={
            <Radio {...controlProps("currentshift")} sx={{
              color: "secondary",
              '&.Mui-checked': {
                color: colors.dashbordGreen[500],
              },
            }} />
          }
          color="success"
          label={<FormattedMessage {...messages.currentShiftFilter} />}
        />
        <FormControlLabel
          value="prevShift"
          control={<Radio {...controlProps("prevShift")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.prevShiftFilter} />}
        />
        <FormControlLabel
          value="24h"
          control={<Radio {...controlProps("24h")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.dayFilter} />}
        />
        <FormControlLabel
          value="72h"
          control={<Radio {...controlProps("72h")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.threeDayFilter} />}
        />
        <FormControlLabel
          value="manual"
          control={<Radio {...controlProps("manual")} sx={{
            color: "secondary",
            '&.Mui-checked': {
              color: colors.dashbordGreen[500],
            },
          }} />}
          label={<FormattedMessage {...messages.manualFilter} />}
        />
      </RadioGroup>
    </FormControl>
  );

  if (timespanQuery.isLoading && showDatepicker === false) {
    return <AxionControls.AxionBackdrop open={true} />
  }
  if (dateQuery.isLoading && showDatepicker === true) {
    return <AxionControls.AxionBackdrop open={true} />
  }
  if (machinesQuery.isLoading) {
    return <AxionControls.AxionBackdrop open={true} />
  }
  if (companyQuery.isLoading) {
    return <AxionControls.AxionBackdrop open={true} />
  }
  if (openActivitiesQuery.isLoading) {
    return <AxionControls.AxionBackdrop open={true} />
  }

  columns = initColumnsFromLocalStorage(columns);

  let query = showDatepicker ? dateQuery : timespanQuery;
  if (query.isSuccess) {

    content = (
      <DataGridPro 
        onColumnResize={handleColumnResize}
        onColumnOrderChange={handleColumnOrderChange}
        autoHeight
        apiRef={gridApi}
        rows={query.data}
        loading={query.isLoading}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        onColumnVisibilityModelChange={(columnState) => {
          saveState();
        }}

        filterModel={filtersStorage}
        onFilterModelChange={(newFilterModel) => {
          setFiltersStorage(newFilterModel);
        }}
        onSortModelChange={(newSortModel) => {
          setSortModelStorage(newSortModel);
        }}
        components={{
          Footer: CustomFooterTotalComponent,
          Toolbar: isOnMobile ? undefined : GridToolbar,
        }}
        componentsProps={{
          footer: {
            machinesRunning,
            machinesStopped,
            machinesPlannedStop
          },
        }}
       onStateChange ={(state) => {
          const visibleRows = gridExpandedSortedRowIdsSelector(state);
          const res = query.data.filter((item) =>
            visibleRows.includes(item.id)
          );
          const running = res.filter(
            (item) => item.machineStatus === "Running"
          );
          const stopped = res.filter(
            (item) => item.machineStatus === "Stopped"
          );
          const offline = res.filter(
            (item) => item.machineStatus === "Offline"
          );
          const stoppedPlanned = res.filter((item) => item.plannedStop === "1");
          setMachinesRunning(running.length);
          setMachinesStopped(stopped.length - stoppedPlanned.length);
          setMachinesPlannedStop(stoppedPlanned.length + offline.length);

          const totalT =
            Object
              .keys(res)
              .reduce((sum, key) => {
                if (res[key].t < 100) {
                  return sum + res[key].t;
                } else {
                  return sum + 100;
                }
              }, 0) / res.length;
          setTotalT(totalT);
          
          const totalA =
            Object
              .keys(res)
              .reduce((sum, key) => {
                if(!isPerfomanceActiveForMachine(res[key].id)) {
                  return sum + 0;
                }
                if (res[key].a < 100) {
                  return sum + res[key].a;
                } else {
                  return sum + 100;
                }
              }, 0) / res.filter(m => isPerfomanceActiveForMachine(m.id) ).length;
          setTotalA(totalA);

          const totalK =
            Object
            .keys(res)
              .reduce(function (sum, key) {
                if(!isQualityActiveForMachine(res[key].id)) {
                  return sum + 0;
                }
                if (res[key].k < 0) {
                  return sum;
                } else {
                  return sum + res[key].k;
                }
              }, 0) / res.filter(m => isQualityActiveForMachine(m.id) ).length;
          setTotalK(totalK);

          setTotal(
              (totalT / 100) *
              (totalA / 100) *
              (totalK / 100) *
              100
          );
        }}
        initialState={{
          columns: {
            columnVisibilityModel: columnStorage,
          },
          sorting: {
            sortModel: sortModelStorage,
          },
        }}
        columns={columns}
      />
    );
  }

    return (
    <Box m="20px"  sx={{ backgroundColor: colors.primary[700] }}>
      <Box>
        <Grid container spacing={0} sx={{ paddingTop: "0px" }}>
          <Grid item xs={12} lg={8}>
            <Stack direction="row" width="100%" marginLeft={2} spacing={2}>
              {/* DatePickers */}
              {showDatepicker && (
                <>
                  <GridDateTimePicker
                    label="From date"
                    labelTime="From time"
                    locale="sv"
                    maxDate={Date().toLocaleString()}
                    minDate=""
                    onChange={startDateChangeHandler}
                    date={startDate}
                  />
                  <GridDateTimePicker
                    label="To date"
                    labelTime="From time"
                    locale="sv"
                    maxDate={Date().toLocaleString()}
                    minDate=""
                    onChange={endDateChangeHandler}
                    date={endDate}
                  />
                </>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ justifyContent: "end", display: "flex" }}>
            {radiobuttons}
          </Grid>
          <Grid item xs={12} lg={12}>
            <Stack direction="row" spacing={2}>
                <Stack direction="row" marginLeft={2} spacing={2} sx={{ alignItems: "flex-end" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked.running}
                        sx={{
                          '& .MuiSwitch-switchBase.Mui-checked': {
                            color: "#799c4a !important",
                            '&:hover': {
                              backgroundColor: alpha("#799c4a", theme.palette.action.hoverOpacity),
                            },
                          },
                          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: "#799c4a !important",
                          }
                        }}
                        onChange={handleCheck}
                        name="running"
                      />
                    }
                    label={<FormattedMessage {...messages.onlyRunningFilter} />}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked.onlyPlanned}
                        color="secondary"
                        sx={{

                          '& .MuiSwitch-switchBase.Mui-checked': {
                            color: "#799c4a !important",
                            '&:hover': {
                              backgroundColor: alpha("#799c4a", theme.palette.action.hoverOpacity),
                            },
                          },
                          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: "#799c4a !important",
                          }
                        }}
                        onChange={handleCheck}
                        name="onlyPlanned"
                      />
                    }
                    label={
                      <FormattedMessage {...messages.dontShowPlannedStopsFilter} />
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked.onlyStopped}
                        sx={{

                          '& .MuiSwitch-switchBase.Mui-checked': {
                            color: "#799c4a !important",
                            '&:hover': {
                              backgroundColor: alpha("#799c4a", theme.palette.action.hoverOpacity),
                            },
                          },
                          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: "#799c4a !important",
                          }
                        }}
                        onChange={handleCheck}
                        name="onlyStopped"
                      />
                    }
                    label={<FormattedMessage {...messages.onlyStoppedFilter} />}
                  />
                  <IconButton 
                    onClick={() => {
                      clearAllFilters();
                    }}
                    title={formatMessage(messages.clearAllFilters)}
                  >
                    <FilterAltOffIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ flexGrow: 1 }}>
                  <OeeSummarize 
                    total={total} 
                    totalColor={totalColor} 
                    totalT={totalT} 
                    totalTColor={totalTColor}
                    totalA={totalA} 
                    totalAColor={totalAColor}
                    totalK={totalK} 
                    totalKColor={totalKColor}
                    />
                  </Box>
              </Stack>
          </Grid>
          
        </Grid>
      </Box>
      <Box
        m="0px 0 0 0"
        height="88vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
          },
          "& .name.column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "13px",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[600],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.gray[100]} !important`,
          },
          "& .super-app-theme--11": {
            bgcolor: `${colors.redAccent[500]} !important`,
          },
          "& .MuiDataGrid-columnHeader": {
            fontSize: "15px",
          },
        }}
      >
        {/*DATAGRID */}
        <div style={{ display: "flex", height: "95%" }}>
          <div style={{ flexGrow: 1 }}>{content}</div>
        </div>
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
      <Popup
        title="Production Order Comments"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <WorkOrderCommentForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          workOrder={workOrder}
        />
      </Popup>
    </Box>
  );
};

export default Dashboard;
