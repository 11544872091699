import { defineMessages } from 'react-intl';

export const scope = 'axionoee.features.machinesstatus.index';

export default defineMessages({
  machinesFilter: {
    id: `${scope}.grid.filters.machines`,
    defaultMessage: 'Machines',
  },
  fieldsToShow: {
    id: `${scope}.grid.fieldsToShow`,
    defaultMessage: 'Fields',
  },
  oee: {
    id: `${scope}.grid.fieldsToShow.oee`,
    defaultMessage: 'OEE',
  },
  a: {
    id: `${scope}.grid.fieldsToShow.a`,
    defaultMessage: 'A',
  },
  p: {
    id: `${scope}.grid.fieldsToShow.p`,
    defaultMessage: 'P',
  },
  q: {
    id: `${scope}.grid.fieldsToShow.q`,
    defaultMessage: 'Q',
  },
});
